import { CCard, CCardBody, CButton } from '@coreui/react';
import React, { useEffect, useState } from 'react';
import { SwitchPopup } from '../../../../general/popup';
import { callSalesforceApi, callTokenApi } from '../../../../../apiCaller';
import { useDispatch, useSelector } from 'react-redux';
import { API_CLIENT_EXTERNAL_DATA, API_CLIENT_EXTERNAL_UPDATE_DATA, API_SALESFORCE_CONNECTION } from '../../../../../constants';
import { getURLImg, toastError } from '../../../../../utils';
import { fetchGoals, setAccountExternalDataSources } from '../../../../../actions/subscriber';
import CenterSpinner from '../../../../general/Loadings/CenterSpinner';
import CIcon from '@coreui/icons-react';
import { useHistory } from 'react-router-dom';
import { setFlexibleModal } from '../../../../../actions/common';
import CSwitch from '../../../../migration/CSwitch';

const ManageExternalItem = () => {
     const [isLoading, setIsLoading] = useState(false);
     const [tempItem, setTempItem] = useState();
     const [turningOnPopup, setTurningOnPopup] = useState(false);
     const [turningOffPopup, setTurningOffPopup] = useState(false);
     const [status, setStatus] = useState();
     const dispatch = useDispatch();
     const history = useHistory();

     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const { id: activeAccountId, timeZone } = activeAccount;

     const sources = useSelector((state) => (state.subscriber.accountExternalData ? state.subscriber.accountExternalData.sources : null));

     useEffect(() => {
          if (!sources && activeAccountId) {
               setIsLoading(true);
               callTokenApi(`${API_CLIENT_EXTERNAL_DATA}/${activeAccountId}`, 'GET')
                    .then((response) => {
                         if (response.status === 200) {
                              dispatch(setAccountExternalDataSources(response.data.externalData));
                         } else {
                              toastError(response);
                         }
                    })
                    .finally(() => setIsLoading(false));
          }
     }, []); // eslint-disable-line react-hooks/exhaustive-deps

     const checkSalesforceConnections = () => {
          return callSalesforceApi(`${API_SALESFORCE_CONNECTION}/count-connection`, 'POST', {
               accountId: activeAccountId
          })
          .then((response) => {
               if (response && response.status === 200) {
                    return !response.data.status; // Returns true if no active connections
               }
               return false;
          })
          .catch((error) => {
               toastError(error);
               return false;
          });
     };

     const acceptSwitching = (itemId) => {
          setIsLoading(true);

          const updatedStatus = status === 'on' ? false : true;
          const data = {
               accountId: activeAccountId,
               externalId: tempItem.id,
               status: updatedStatus,
               timeZone,
          };

          const salesforceSource = sources.some((source) => source.id === itemId && source.alias === 'salesforce');

          if (salesforceSource && !updatedStatus) {
               checkSalesforceConnections().then((canProceed) => {
                    if (canProceed) {
                         updateExternalData(data);
                    } else {
                         toastError({
                              data: {
                                   message: 'You must delete all connections.'
                              }
                         });
                    }
               }).finally(() => {
                    resetLoadingState();
               });
          } else {
               updateExternalData(data).finally(() => {
                    resetLoadingState();
               });
          }
     };

     const updateExternalData = (data) => {
          return callTokenApi(`${API_CLIENT_EXTERNAL_UPDATE_DATA}`, 'POST', data)
               .then((response) => {
                    if (response.status === 200) {
                         const sourceIndex = sources.findIndex((source) => source.id === tempItem.id);
                         const updatedSources = [...sources];
                         updatedSources[sourceIndex].accountExternalData = response.data.externalAccountData;
                         dispatch(setAccountExternalDataSources(updatedSources));
                         dispatch(fetchGoals(activeAccount.id, activeAccount.secondId))
                    } else {
                         toastError(response);
                    }
               });
     };

     const resetLoadingState = () => {
          setTurningOffPopup(false);
          setTurningOnPopup(false);
          setIsLoading(false);
     };

     const toggleItem = (item, status) => {
          setTempItem(item);
          setStatus(status);

          if (status === 'on') {
               setTurningOffPopup(true);
          } else {
               setTurningOnPopup(true);
          }
     };

     const handleLinkToPageDetail = (alias) => {
          history.push(`/${activeAccount.secondId}/external-data/${alias}`);
          dispatch(setFlexibleModal({ show: false, component: '' }));
     };

     return (
          <div className="library-wrapper">
               <div className="listener-items row-items">
                    {!sources ? (
                         <CenterSpinner />
                    ) : (
                         sources.map((source) => {
                              if (source.code === 'fromFile') {
                                   source.isComingSoon = true;
                              }
                              const { name, description, isComingSoon, accountExternalData, alias } = source;
                              const icon = getURLImg(alias);
                              const configStatus = accountExternalData && accountExternalData.status;
                              return (
                                   <div className="listener-item" key={source.name}>
                                        <CCard>
                                             <CCardBody>
                                                  <div className="card-title d-flex justify-content-between w-100">
                                                       <div className="d-flex">
                                                            <img src={icon} alt="Icon" className="icon-20 mr-2" />
                                                            <h4>{name}</h4>
                                                       </div>
                                                  </div>

                                                  <div className="card-text h-100">{description && <p className="f-14">{description}</p>}</div>
                                                  <div className={`card-icon d-flex justify-content-end`}>
                                                       <div className="card-header-actions">
                                                            {isComingSoon ? (
                                                                 <span>Coming soon</span>
                                                            ) : (
                                                                 <>
                                                                      {configStatus && (
                                                                           <CButton className='action-open-menu mr-2' onClick={() => handleLinkToPageDetail(source.alias)}>
                                                                                <CIcon name='cil-settings' height='16' />
                                                                           </CButton>
                                                                      )}

                                                                      <CSwitch
                                                                           className="float-right mb-0"
                                                                           color={configStatus ? 'success' : 'light'}
                                                                           checked={!!configStatus} // !!false = false, !!undefined = false, !!true = true
                                                                           value={configStatus ? 'on' : 'off'}
                                                                           size="lg"
                                                                           tabIndex="0"
                                                                           shape="pill"
                                                                           onChange={(e) => toggleItem(source, e.target.value)}
                                                                           disabled={isLoading}
                                                                      />
                                                                 </>
                                                            )}
                                                       </div>
                                                  </div>
                                             </CCardBody>
                                        </CCard>
                                   </div>
                              );
                         })
                    )}
                    <SwitchPopup
                         show={turningOnPopup}
                         isLoading={isLoading}
                         onAccept={() => acceptSwitching(tempItem.id)}
                         onCancel={() => setTurningOnPopup(false)}
                    >
                         <h2>Enable External Data</h2>
                         <div className="confirm-save-content">
                              You're about to enable this external data. You will still need to publish your changes before this goes live.
                         </div>
                    </SwitchPopup>
                    <SwitchPopup
                         show={turningOffPopup}
                         onAccept={() => acceptSwitching(tempItem.id)}
                         onCancel={() => setTurningOffPopup(false)}
                         isLoading={isLoading}
                    >
                         <h2>Disable External Data</h2>
                         <div className="confirm-save-content">
                              Once a external data is disabled, all settings and rules for it will be lost and cannot be restored. Are you sure you
                              want to make this change?
                         </div>
                    </SwitchPopup>
               </div>
          </div>
     );
};

export default ManageExternalItem;
