export const getListJobs = (payload) => {
     return {
          data: payload.data,
          total: payload.total,
          itemsPerPage: payload.itemsPerPage,
          activePage: payload.activePage,
     };
}

export const updateJobs = (state, payload) => {
     const { SFJobs } = state;
     const jobs = SFJobs.data;
     const newJobs = jobs.map((job) => {
          const jobUpdate = payload.find((_item) => _item.id === job.id);
          if (jobUpdate) {
               job.retry = jobUpdate.retry;
               job.status = jobUpdate.status;
               job.totalSyncCount = jobUpdate.totalSyncCount;
               job.syncFailedCount = jobUpdate.syncFailedCount;
               job.syncCompletedCount = jobUpdate.syncCompletedCount;
               job.syncNewCount = jobUpdate.syncNewCount;
               job.syncModifiedCount = jobUpdate.syncModifiedCount;
          }
          return job;
     })
     return {
          data: newJobs,
          total: SFJobs.total,
          itemsPerPage: SFJobs.itemsPerPage,
          activePage: SFJobs.activePage,
     }
}

export const updateSaleForcePaging = (state, payload) => {
     const total = payload.salesforceRuleTotal;
     const active = payload.salesforceRuleActivePage;
     const itemsPerPage = payload.itemsPerPage;
     return {
          salesforceRuleTotal: total ? total : state.salesforceRuleTotal,
          salesforceRuleActivePage: active ? active : state.salesforceRuleActivePage,
          itemsPerPage: itemsPerPage ? itemsPerPage : state.itemsPerPage,
     };
};

export const updateProgressRule = (state, payload) => {
     const SFjobActive = state;

     if (!SFjobActive || (SFjobActive && SFjobActive.ruleId === payload.ruleId)) {
          return payload;
     } else {
          return SFjobActive;
     }
};