import React, { useState, useEffect } from "react";
import { callTokenApi } from "../../../../../apiCaller";
import { API_CLIENT_SEVER_SIDE_TRIGGER_DETAIL } from "../../../../../constants";
import { toastError } from "../../../../../utils";
import { RuleContext } from "./CreateNewRule";
import CenterSpinner from "../../../../general/Loadings/CenterSpinner";

//sever side triggers
const StepTrigger = React.lazy(() => import("./StepTrigger"));
const SeverSideStep2 = React.lazy(() => import("./sever-side-trigger/CreateStep2"));
const SeverSideStep3 = React.lazy(() => import("./sever-side-trigger/CreateStep3"));

const CreateNewSeverSideTrigger = ({ dataSeverSide }) => {
     const [stepsData, setStepsData] = useState({ ...dataSeverSide });

     useEffect(() => {
          setStepsData({ ...dataSeverSide });
     }, [dataSeverSide]);

     return (
          <>
               <SeverSideStep2 stepsData={stepsData} setStepsData={setStepsData} />
               <SeverSideStep3 stepsData={stepsData} setStepsData={setStepsData} />
          </>
     );
};

const EditSeverSideTrigger = ({ id }) => {
     const [activeStep, setActiveStep] = useState();
     const [manageType, setManageType] = useState("");
     const [dataSeverSide, setDataSeverSide] = useState();
     const [fetchSSTriggerLoading, setFetchSSTriggerLoading] = useState();

     useEffect(() => {
          if (dataSeverSide) {
               setActiveStep(2);
          }
     }, [dataSeverSide]);

     const fetchSeverSideTriggerDetail = () => {
          if (!id) return;
          setFetchSSTriggerLoading(true);
          callTokenApi(API_CLIENT_SEVER_SIDE_TRIGGER_DETAIL.replace(":id", id), "GET")
               .then((response) => {
                    if (response.status === 200) {
                         let { trigger } = response.data;

                         trigger = {
                              ...trigger,
                              group: "sever-side-trigger",
                         };
                         setDataSeverSide(trigger);
                    } else {
                         toastError(response);
                    }
               })
               .finally(() => {
                    setManageType("sever-side-trigger");
                    setFetchSSTriggerLoading(false);
               });
     };
     useEffect(fetchSeverSideTriggerDetail, []); // eslint-disable-line react-hooks/exhaustive-deps

     return (
          <>
               {fetchSSTriggerLoading ? (
                    <CenterSpinner />
               ) : (
                    <div className='rule-detail'>
                         <React.Suspense fallback={<CenterSpinner />}>
                              {
                                   <div className='cvr-create-new custom-rule'>
                                        <h1>Edit Sever-Side Trigger Rule : {dataSeverSide && dataSeverSide.name}</h1>
                                        <p>Use the steps below to configure your rule.</p>

                                        <StepTrigger
                                             activeStep={activeStep}
                                             setActiveStep={setActiveStep}
                                             manageType={manageType}
                                             setManageType={setManageType}
                                             actionType='edit'
                                             id={id}
                                        />

                                        <RuleContext.Provider value={{ activeStep, setActiveStep, id }}>
                                             <CreateNewSeverSideTrigger dataSeverSide={dataSeverSide} />
                                        </RuleContext.Provider>
                                   </div>
                              }
                         </React.Suspense>
                    </div>
               )}
          </>
     );
};

export default EditSeverSideTrigger;
