import React, { Suspense, useEffect } from 'react'
import {
     Route,
     Switch,
     Redirect,
     useHistory,
     useLocation
} from 'react-router-dom';
import { CContainer } from '@coreui/react';
import { useSelector, useDispatch } from 'react-redux';
import isObject from 'lodash/isObject';

// routes config
import { defaultSubscriberRoutes } from '../../routes';
import CreateAccount from '../../components/cms/subscriber/account/create-account/CreateAccount';
import UpgradeAccount from '../../components/cms/subscriber/account/upgrade-account/UpgradeAccount';
import BlockAccount from '../../components/cms/subscriber/account/block-account/BlockAccount';
import BreadcrumbRouter from '../../components/general/breadcrumb/BreadcrumbRouter';
import { SUBSCRIBER_PATH } from '../../constants';
import EventReporting from '../../components/cms/subscriber/event-reporting/EventReporting';
import { TermAndConditionPopup } from '../../components/general/popup';
import CenterSpinner from '../../components/general/Loadings/CenterSpinner';
import AccountDormant from '../../components/cms/subscriber/account/account-dormant/AccountDormant';
import CFade from '../../components/migration/CFade';
import CSubheader from '../../components/migration/CSubheader';
import { setFlexibleModal } from '../../actions/common.js';

const SubscriberContent = () => {
     const dispatch = useDispatch();
     const history = useHistory();
     const location = useLocation();
     let routes = useSelector((state) => state.subscriber.routes);
     const accountExternalData = useSelector((state) => state.subscriber.accountExternalData);
     const accountExternalSources =
          accountExternalData && accountExternalData.sources && accountExternalData.sources.length > 0 && accountExternalData.sources;
     const lastSegment = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);

     useEffect(() => {
          const routeExternalData =
               (accountExternalSources &&
                    accountExternalSources
                         .filter((i) => i.accountExternalData && i.accountExternalData.status)
                         .map((item) => {
                              return item.alias;
                         })) ||
               [];

          if (
               accountExternalSources &&
               accountExternalSources.map((i) => i.alias).includes(lastSegment) &&
               !routeExternalData.includes(lastSegment) &&
               window.location.pathname.includes('external-data')
          ) {
               history.push(`/${activeAccount.secondId}`);
               dispatch(setFlexibleModal({ show: false, component: '' }));
          }
     }, [accountExternalData.sources]); //eslint-disable-line

     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const accountsLoading = useSelector((state) => state.subscriber.loadings.accounts);
     const user = useSelector((state) => state.theme.user);
     const listenersLoading = useSelector((state) => state.subscriber.loadings.listeners);
     const accountLoading = useSelector((state) => state.subscriber.loadings.account);
     const gtmTokenLoading = useSelector((state) => state.subscriber.loadings.gtmToken);
     const activeAccountPackageEventReporting = useSelector((state) => state.subscriber.activeAccount.packageEventReporting);

     if (activeAccountPackageEventReporting) {
          if (!routes.some((route) => route.path === SUBSCRIBER_PATH.GLOBAL_REPORTING)) {
               routes.push({ path: SUBSCRIBER_PATH.GLOBAL_REPORTING, name: 'Global Reporting', component: EventReporting });
          }
          if (!routes.some((route) => route.path === SUBSCRIBER_PATH.INSIGHTS)) {
               routes.push({ path: SUBSCRIBER_PATH.INSIGHTS, name: 'Analytics', component: EventReporting });
          }
     }

     return (
          <main className="c-main">
               <CSubheader className="main-breadcrumb justify-content-between">
                    <BreadcrumbRouter className="border-0 c-subheader-nav m-0 px-4 px-md-3 d-flex align-items-center" routes={routes} />
               </CSubheader>
               <CContainer fluid>
                    {accountsLoading || listenersLoading || accountLoading || gtmTokenLoading ? (
                         <CenterSpinner />
                    ) : (
                         <Suspense fallback={<CenterSpinner />}>
                              <Switch>
                                   {routes.map((route, idx) => {
                                        return (
                                             route.component && (
                                                  <Route
                                                       key={route.path}
                                                       path={route.path}
                                                       exact={route.exact}
                                                       name={route.name}
                                                       render={(props) => {
                                                            const finalProps = isObject(route.props) ? { ...props, ...route.props } : { ...props };

                                                            return (
                                                                 <CFade>
                                                                      <route.component {...finalProps} />
                                                                 </CFade>
                                                            );
                                                       }}
                                                  />
                                             )
                                        );
                                   })}
                                   {(routes.length >= defaultSubscriberRoutes.length) && <Redirect to={`/${activeAccount.secondId}`} />}
                              </Switch>
                              <CreateAccount />
                              {/* <div className="full-screen-popup">
                                             <ComparePlansPopup />
                                        </div> */}
                              <UpgradeAccount />

                              <AccountDormant />
                              {!user.registerMasterClass && <BlockAccount />}
                              <TermAndConditionPopup />
                              {/* {
                                        previewRunning && (
                                             <CFade timeout={300} in={!previewShow}>
                                                  <CButton
                                                       onClick={() => {
                                                            dispatch(setTogglePreviewPopup(true));
                                                       }}
                                                       className="box-preview">
                                                       <CIcon icon="iconExpand" className='mr-2' />
                                                       Live Test
                                                       <span className={`pulse-status${previewRunning ? ' online' : ''}`}></span>
                                                  </CButton>
                                             </CFade>
                                        )
                                   } */}
                         </Suspense>
                    )}
               </CContainer>
          </main>
     );
};

export default React.memo(SubscriberContent);
