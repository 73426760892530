import React, { useContext, useEffect, useState } from 'react';
import { CButton, CCard, CCardBody, CCardHeader, CCol, CForm, CRow } from '@coreui/react';
import { Formik } from 'formik';
// import { useSelector } from 'react-redux';
import { SalesforceDetailDetailContext } from "../Popup/SalesforceConnectAccount";
import CenterSpinner from "../../../../../../../general/Loadings/CenterSpinner";
import { HoverInfoIconTooltip } from "../../../../../../../general/custom-tooltips";
import {
     EXTERNAL_DATA_CONNECTION_STATUS,
     API_SALESFORCE_GET_OAUTH_URL,
     COMPONENT_NAME,
     API_SALESFORCE_CONNECTION_DISCONNECT,
     SF_CONNECTION_STATUS,
     SF_JOB_STATUS,
     // SF_CONNECTION_STATUS,
} from '../../../../../../../../constants';
import { callSalesforceApi } from '../../../../../../../../apiCaller';
import { toast } from 'react-toastify';
import { setRuleHaveEditting } from '../../../../../../../../actions/common';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSalesforceRulesRequest, setSalesforceAccounts } from '../../../../../../../../actions/subscriber';
import { ConfirmSaveChange } from '../../../../../../../general/popup';
import { fetchSalesforceJobsRequest } from '../../../../../../../../actions/external';
import CImg from '../../../../../../../migration/CImg';

const SelectAccountSalesforce = () => {
     const dispatch = useDispatch();
     const { accountId } = useSelector((state) => state.subscriber.activeAccount);
     const SFjobs = useSelector((state) => state.subscriber.accountExternalData.SFJobs);
     const jobs = useSelector((state) => state.subscriber.jobs);
     const listAccountConnected = useSelector((state) => state.subscriber.accountExternalData.salesforceAccounts);
     const salesForeRulesPaging = useSelector((state) => state.subscriber.accountExternalData.salesForeRulesPaging);
     const { activeStep, setActiveStep, stepsData, setStepsData, setInitStepsData } = useContext(SalesforceDetailDetailContext);
     const objects = typeof stepsData.objects === 'string' ? JSON.parse(stepsData.objects) : stepsData.objects;

     const [isLoading, setIsLoading] = useState(false);
     const [isLoadingConnect, setIsLoadingConnect] = useState(false);
     const [showPopupDelete, setShowPopupDelete] = useState(false);

     const [statusConnect, setStatusConnect] = useState(EXTERNAL_DATA_CONNECTION_STATUS.noConnect);
     const [initialValues, setInitialValues] = useState({
          email: stepsData.email || "",
          accountId: stepsData.accountId || "",
          salesforceName: stepsData.salesforceName || "",
     });

     const onSubmit = () => {
          setActiveStep(2);
     };

     useEffect(() => {
          if (stepsData.connectionData) {
               setStatusConnect(EXTERNAL_DATA_CONNECTION_STATUS.active);
               setInitialValues({
                    email: stepsData.connectionData.email,
                    accountId: stepsData.accountId,
                    salesforceName: stepsData.salesforceName,
                    salesforceId: stepsData.salesforceId,
               });

               const existingIndex = listAccountConnected.findIndex((item) => item.id === stepsData.id);

               if (existingIndex >= 0) {
                    dispatch(
                         setSalesforceAccounts(
                              listAccountConnected.map((item, index) =>
                                   index === existingIndex ? { ...item, connectionData: stepsData.connectionData, status: stepsData.status } : item
                              )
                         )
                    );
               }
          } else {
               setStatusConnect(EXTERNAL_DATA_CONNECTION_STATUS.noConnect);
          }
     }, [stepsData]); // eslint-disable-line react-hooks/exhaustive-deps

     useEffect(() => {
          const getDataAccount = (event) => {
               if (event.origin === window.location.origin && event.data && event.data.type === "connectSalesforce") {
                    const receivedData = event.data;

                    if (receivedData.success) {
                         if (receivedData.connection.existed) {
                              toast.warn("Salesforce account has been connected!");
                         } else {
                              const connection = receivedData.connection.data;

                              const maxDailyAPICall = connection.DailyApiRequests ? connection.DailyApiRequests.Max : 2000;
                              let numberOfRequest = 0;

                              if (connection.DailyApiRequests) {
                                   numberOfRequest = connection.DailyApiRequests.Max - connection.DailyApiRequests.Remaining;
                              }

                              setStepsData((prev) => ({
                                   ...prev,
                                   ...connection,
                                   maxDailyAPICall,
                                   numberOfRequest,
                                   maxAPIUsage: maxDailyAPICall,
                              }));

                              const existingIndex = listAccountConnected.findIndex((item) => item.id === connection.id);

                              const newListAccount = existingIndex >= 0
                                   ? listAccountConnected.map((item, index) => 
                                        index === existingIndex ? { ...item, connectionData: connection.connectionData, connection } : item)
                                   : [connection, ...listAccountConnected];

                              dispatch(setSalesforceAccounts(newListAccount));
                              dispatch(setRuleHaveEditting({ show: true, type: COMPONENT_NAME.SALESFORCE_CONNECTED_ACCOUNTS }));
                              dispatch(fetchSalesforceRulesRequest(accountId, false, 1, salesForeRulesPaging.itemsPerPage));
                              toast.success('Connect salesforce successfully!');
                         }
                    } else {
                         toast.error(receivedData.errorMessage);
                    }
               }
          };

          // listener message from connect salesforce post.
          window.addEventListener("message", getDataAccount);

          return () => {
               window.removeEventListener("message", getDataAccount);
          };
     }, []); //eslint-disable-line

     const handleSignIn = async () => {
          setIsLoading(true);
          let request = API_SALESFORCE_GET_OAUTH_URL;

          if (window.location.hostname === "localhost") {
               request = `${API_SALESFORCE_GET_OAUTH_URL}?redirect_url=http://localhost:3000/salesforce`;
          }

          callSalesforceApi(request, "GET")
               .then((response) => {
                    if (response && response.status === 200) {
                         const { oauth_url } = response.data;

                         if (oauth_url) {
                              if (stepsData.id) {
                                   localStorage.setItem("activeConnectionId", stepsData.id);
                              }

                              window.open(oauth_url);
                         }
                    } else {
                         toast.error("Connect Salesforce fail!");
                    }
               })
               .finally(() => {
                    setIsLoading(false);
               });
     };

     const handleChangeConnect = () => {
          setIsLoadingConnect(true);
          callSalesforceApi(API_SALESFORCE_CONNECTION_DISCONNECT, "POST", { id: stepsData.id })
               .then((response) => {
                    if (response && response.status === 200) {
                         setStatusConnect(EXTERNAL_DATA_CONNECTION_STATUS.noConnect);
                         setStepsData((prev) => ({
                              ...prev,
                              status: 3,
                              salesforceId: null,
                              connectionData: null,
                              objects: [],
                         }));

                         setInitStepsData((prev) => ({
                              ...prev,
                              salesforceId: null,
                              connectionData: null,
                              objects: [],
                         }));

                         const existingIndex = listAccountConnected.findIndex((item) => item.id === stepsData.id);

                         if (existingIndex >= 0) {
                              dispatch(setSalesforceAccounts(
                                   listAccountConnected.map((item, index) => 
                                        index === existingIndex ? { ...item, status: SF_CONNECTION_STATUS.DISABLED } : item)
                              ));
                         }

                         dispatch(fetchSalesforceRulesRequest(accountId, false, 1, salesForeRulesPaging.itemsPerPage));
                         dispatch(fetchSalesforceJobsRequest(accountId, 1, SFjobs.itemsPerPage));
                         toast.success('Disconnect Salesforce Success');
                    } else {
                         toast.error("Disconnect Salesforce fail!");
                    }
               })
               .finally(() => {
                    setIsLoadingConnect(false);
                    setShowPopupDelete(false);
               });
     };

     // const handleChange = (e) => {
     //      let {name, value} = e.target;
     //      setInitialValues((prev) => ({...prev, [name]: value }))
     // }

     const checkStepPassed = () => {
          let passed = true;
          // const { status, maxDailyAPICall } = stepsData;

          // if (status === SF_CONNECTION_STATUS.DRAFT || !maxDailyAPICall) {
          //      passed = false;
          // }

          return passed;
     };

     const getRelatedObjects = (objects = []) => {
          const relatedObjectData = {
               Account: 'AccountContactRelation',
               Opportunity: 'OpportunityContactRole',
               Contract: 'ContractContactRole',
               Campaign: 'CampaignMember',
               Event: 'EventRelation',
          };

          return objects.map((obj) => relatedObjectData[obj]).filter(Boolean);
     };

     let jobLength = 0;

     if (Array.isArray(objects) && objects.length > 0) {
          const relatedObjects = getRelatedObjects(objects);
          const allObjects = new Set([...objects, ...relatedObjects]);

          jobLength = allObjects.size;
     }
     
     const jobConnection = jobs ? jobs.filter(item => item.connectionId === stepsData.id) : [];
     const hasSyncData = stepsData && stepsData.jobs && stepsData.jobs.length > 0;
     const hasFininedData = jobConnection.some((_job) => _job.status !== SF_JOB_STATUS.FINISHED);
     const checkChangeConnection = hasSyncData && (hasFininedData || jobConnection.length !== jobLength);

     return (
          <CCard className={`dimension-source ${activeStep === 1 ? "show" : "hide"}`}>
               <CCardHeader>
                    {activeStep !== 1 ? (
                         <div className="rule-step d-inline-flex justify-content-between w-100">
                              <h5 className="mb-0 inactive">Step 1: Select Account</h5>
                              {checkStepPassed() && (
                                   <div className="d-flex">
                                        <div className="pr-3">
                                             <span>
                                                  Email: {initialValues.email} | {initialValues.salesforceName} | Account ID:{" "}
                                                  {initialValues.salesforceId}
                                             </span>
                                        </div>
                                        <div className="d-inline-flex align-items-center">
                                             <CButton className="btn-edit" onClick={() => setActiveStep(1)}>
                                                  Edit
                                             </CButton>
                                        </div>
                                   </div>
                              )}
                         </div>
                    ) : (
                         <h5 className="mb-0">Step 1: Select Account</h5>
                    )}
               </CCardHeader>
               {activeStep === 1 && (
                    <CCardBody>
                         <p>
                              Login to your Salesforce CRM account and we'll allow yout to begin configuring the account for data imports. Your user
                              must have API access to the CRM account.
                         </p>
                         {/* {fetchLoading ? (
                              <CenterSpinner />
                         ) : ( */}
                         <Formik initialValues={initialValues} onSubmit={onSubmit} validateOnBlur={false}>
                              {({ handleSubmit, isSubmitting }) => {
                                   return (
                                        <CForm onSubmit={handleSubmit} noValidate>
                                             <>
                                                  {statusConnect === EXTERNAL_DATA_CONNECTION_STATUS.noConnect && (
                                                       <>
                                                            {isLoading && (
                                                                 <div style={{ width: "100%", height: "80px", position: "relative" }}>
                                                                      <CenterSpinner />
                                                                 </div>
                                                            )}
                                                            <CRow className="ml-2">
                                                                 <div style={{ display: "flex", gap: "30px", alignItems: "center" }}>
                                                                      <CButton
                                                                           className="px-4 py-2"
                                                                           color="primary"
                                                                           onClick={handleSignIn}
                                                                           disabled={isSubmitting || isLoading}
                                                                           style={{ textTransform: "none" }}
                                                                      >
                                                                           Connect to Salesforce
                                                                      </CButton>
                                                                      <a
                                                                           href={
                                                                                "https://developer.salesforce.com/docs/atlas.en-us.api_rest.meta/api_rest/intro_rest_compatible_editions.htm"
                                                                           }
                                                                           target="_blank"
                                                                           rel="noopener noreferrer"
                                                                      >
                                                                           Learn More About the Salesforce API for Your Account
                                                                      </a>
                                                                 </div>
                                                            </CRow>
                                                       </>
                                                  )}

                                                  {statusConnect !== "NoConnect" && (
                                                       <>
                                                            {isLoading && (
                                                                 <div style={{ width: "100%", height: "80px", position: "relative" }}>
                                                                      <CenterSpinner />
                                                                 </div>
                                                            )}
                                                            <CRow>
                                                                 <h6 className="ml-3">Connected to the following Salesforce Account</h6>
                                                            </CRow>
                                                            <CRow>
                                                                 <CCol lg="6">
                                                                      <div className="account-connection">
                                                                           <div
                                                                                className="avatar avatar1"
                                                                           >
                                                                                <CImg
                                                                                     src={"/user-default-avatar.png"}
                                                                                     className="avatar-img"
                                                                                     alt="avatar"
                                                                                />
                                                                           </div>
                                                                           <div className="data-account">
                                                                                <p style={{ marginBottom: 0 }}>Email: {initialValues.email}</p>
                                                                                <p style={{ marginBottom: 0 }}>
                                                                                     {initialValues.salesforceName} | Account ID:{" "}
                                                                                     {initialValues.salesforceId}
                                                                                </p>

                                                                                <div className="d-inline-flex">
                                                                                     <CButton
                                                                                          className="btn-save-account pl-0 pt-0"
                                                                                          disabled={isSubmitting || checkChangeConnection}
                                                                                          onClick={() => setShowPopupDelete(true)}
                                                                                          style={{ textTransform: "none" }}
                                                                                     >
                                                                                          Change oAuth Connection
                                                                                     </CButton>{" "}
                                                                                     <div className="tooltip-wrapper">
                                                                                          <HoverInfoIconTooltip>
                                                                                               <p>
                                                                                                    This connection and all Data Import Jobs relying
                                                                                                    on it will be paused until a new oAuth is selected
                                                                                                    and THE SAME Salesforce Account is connected. If
                                                                                                    you need to connect a new Salesforce account,
                                                                                                    create a new connection.
                                                                                               </p>
                                                                                          </HoverInfoIconTooltip>
                                                                                     </div>
                                                                                </div>
                                                                           </div>
                                                                      </div>
                                                                 </CCol>
                                                            </CRow>
                                                       </>
                                                  )}

                                                  {statusConnect !== EXTERNAL_DATA_CONNECTION_STATUS.noConnect && (
                                                       <CButton
                                                            type="submit"
                                                            className="px-4 py-2 btn-next-step"
                                                            color="primary"
                                                            disabled={isSubmitting}
                                                       >
                                                            NEXT STEP
                                                       </CButton>
                                                  )}
                                             </>
                                        </CForm>
                                   );
                              }}
                         </Formik>
                         {/* )} */}
                    </CCardBody>
               )}

               <ConfirmSaveChange
                    show={showPopupDelete}
                    onClose={() => setShowPopupDelete(false)}
                    isLoading={isLoadingConnect}
                    onAccept={handleChangeConnect}
                    title={"Are You Sure You Want to Change Connection ?"}
               >
                    <p>You are about to change this Salesforce Connection.</p>
               </ConfirmSaveChange>
          </CCard>
     );
};

export default SelectAccountSalesforce;
