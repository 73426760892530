import { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import cookie from 'js-cookie';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { CancelToken } from 'axios';

import {
     setAutomaticVariableReload,
     setEventOptions,
     setVariableOptions,
     setEventNativeOptions,
     setNativeBrowserStorageOptions,
     fetchCustomVariables,
     // setSelectorVariableReload,
     setSelectorOptions,
     setBrowserVariables,
     setBrowserVariableOptions,
     setMasterclassCourse,
     setTriggerRules,
     setInitialTriggerRules,
     // setLoadings,
     setTriggersName,
     setPreviewOptions,
     setConversionRules,
     setInitialConversionRules,
     setConversionsName,
     setBlockTriggerRules,
} from '../actions/subscriber';
import { callTokenApi } from '../apiCaller';
import {
     API_CLIENT_ACCOUNT_RULES,
     API_CLIENT_CUSTOM_VARIABLE,
     API_CLIENT_DATALAYER_VARIABLE_RULE,
     // API_CLIENT_EVENT_ACCOUNT,
     API_CLIENT_EVENT_ACCOUNT_RULE,
     API_CLIENT_EVENT_NATIVE_ACCOUNT_RULE,
     API_CLIENT_GET_COURSE,
     API_CLIENT_NATIVE_BROWSER_STORAGE_ACCOUNT_RULE,
     API_CLIENT_SELECTOR_VARIABLE_RULE,
     CREATE_ACCOUNT_MAIN_STEPS,
     CUSTOM_EVENT_NAMES,
     CUSTOM_EVENT_NAMES_V2,
     LATEST_PACKAGE_VERSION,
} from '../constants';
import { toastError, gtmTrackingIdentifyLLUser, tryParseJSON, deepCopyArray, getTimeZoneOffset } from '../utils';

export const useEventAndVariableOptions = (isFetchData, fetchOnlyAutomaticVariables, isFetchCustomVariables) => {
     const dispatch = useDispatch();
     const [fetchVariableLoading, setFetchVariableLoading] = useState(false);
     const [fetchEventLoading, setFetchEventLoading] = useState(false);
     const [fetchCustomVariablesLoading, setFetchCustomVariablesLoading] = useState(false);
     const [fetchSelectorLoading, setFetchSelectorLoading] = useState(false);
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const activeListener = useActiveListener();
     const variableOptions = useSelector((state) => state.subscriber.variableOptions); // This is automatic variable
     const eventOptions = useSelector((state) => state.subscriber.eventOptions);
     const eventNativeOptions = useSelector((state) => state.subscriber.eventNativeOptions);
     const customVariables = useSelector((state) => state.subscriber.customVariables);
     const customVariableOptions = useSelector((state) => state.subscriber.customVariableOptions);
     const nativeBrowserStorageOptions = useSelector((state) => state.subscriber.nativeBrowserStorageOptions);
     const selectorOptions = useSelector((state) => state.subscriber.selectorOptions);
     const automaticVariableReload = useSelector((state) => state.subscriber.automaticVariableReload);
     // const selectorVariableReload = useSelector(state => state.subscriber.selectorVariableReload);
     const activeFeatures = useSelector((state) => state.subscriber.activeFeatures);
     let fetchLoading = fetchVariableLoading || fetchEventLoading || fetchCustomVariablesLoading || fetchSelectorLoading;
     const { listenerId: variableListenerId, variables } = variableOptions;
     const { listenerId: selectorListenerId, variables: selectorVariables } = selectorOptions;
     const { listenerId: eventListenerId, events } = eventOptions;
     const { eventsNative } = eventNativeOptions;
     const { nativeBrowserStorage } = nativeBrowserStorageOptions;

     function capitalizeFirstLetter(string) {
          return string[0].toUpperCase() + string.slice(1);
     }
     // Automatic variable options for react-select
     let reactSelectVariableOptions = variables.map((variableOption) => {
          let parentName = variableOption.friendlyName || variableOption.fullName;
          let variableOptionValue = JSON.stringify({ id: variableOption.id, key: variableOption.fullName });

          return {
               value: variableOptionValue,
               label: parentName,
          };
     });

     // Event options for react-select
     let reactSelectEventOptions = events.map((eventOption) => ({
          value: JSON.stringify({ id: eventOption.id, key: eventOption.code }),
          label: eventOption.friendlyName || eventOption.code,
     }));

     // Event Native options for react-select
     let reactSelectEventNativeOptions = eventsNative.map((eventOption) => ({
          value: JSON.stringify({ id: eventOption, key: eventOption }),
          label: eventOption,
     }));

     // Automatic variable options for react-select limit value - POPULATE
     const _datas = [];
     variables.forEach((variableOption) => {
          let textToCompare;

          if (variableOption.fullName.includes('.name') && !variableOption.fullName.includes('.names[')) {
               textToCompare = 'name';
          } else if (variableOption.fullName.includes('.id')) {
               textToCompare = 'id';
          }

          if (textToCompare) {
               let parentId = variableOption.fullName.split('.');

               if (parentId[2]) {
                    if (typeof parentId[3] === 'string' && parentId[3] !== textToCompare && parentId[2] !== 'name') {
                         _datas.push(`${parentId[2]}.${parentId[3]}`);
                    } else {
                         _datas.push(parentId[2]);
                    }
               }
          }
     });

     let noDuplicateValues = new Set(_datas); // Remove duplicate values
     let reactSelectVariableValueOptions = Array.from(noDuplicateValues).map((val) => ({ value: val, label: val }));

     let reactSelectSelectorValueOptions = selectorVariables.map((option) => ({
          id: option.id,
          friendlyName: option.friendlyName,
          value: JSON.stringify({ id: option.id, key: option.fullName }),
          label: option.friendlyName || option.fullName,
     }));

     // Native Browser Storage options for react-select
     let reactSelectNativeBrowserStorageOptions = nativeBrowserStorage.map((eventOption) => ({
          id: eventOption.nativeId,
          value: JSON.stringify({ id: eventOption, key: eventOption }),
          label: `${capitalizeFirstLetter(eventOption.type)}: ${eventOption.name}, Format: ${capitalizeFirstLetter(eventOption.format)}`,
     }));

     // Custom variable options for react-select
     let reactSelectCustomVariableOptions = customVariableOptions.map((customVariable) => ({
          value: customVariable.id,
          label: customVariable.name,
     }));

     let isSelectorFeatureOpen = activeFeatures.some((feature) => (feature.featureType === 'selector' || feature.type === 'selector') && feature.listenerId === activeListener.id);

     const fetchDatalayerVariables = () => {
          if (!variableListenerId || variableListenerId !== activeListener.id) {
               setFetchVariableLoading(true);
               const urlToCall = `${API_CLIENT_DATALAYER_VARIABLE_RULE}${activeAccount.id}/${activeListener.id}`;

               callTokenApi(urlToCall, 'GET', null)
                    .then((response) => {
                         if (response.status === 200) {
                              dispatch(
                                   setVariableOptions({
                                        listenerId: activeListener.id,
                                        variables: response.data.datalayerVariables,
                                   })
                              );
                         }
                    })
                    .finally(() => setFetchVariableLoading(false));
          }
     };

     const fetchSelectorVariables = async () => {
          if (!selectorListenerId || selectorListenerId !== activeListener.id) {
               setFetchSelectorLoading(true);
               const urlToCall = `${API_CLIENT_SELECTOR_VARIABLE_RULE}${activeAccount.id}/${activeListener.id}`;

               callTokenApi(urlToCall, 'GET', null)
                    .then((response) => {
                         if (response.status === 200) {
                              dispatch(
                                   setSelectorOptions({
                                        listenerId: activeListener.id,
                                        variables: response.data.selectorVariables,
                                   })
                              );
                         }
                    })
                    .finally(() => setFetchSelectorLoading(false));
          }
     };

     const fetchEvents = () => {
          if (!eventListenerId || eventListenerId !== activeListener.id) {
               setFetchEventLoading(true);
               const urlToCall = `${API_CLIENT_EVENT_ACCOUNT_RULE}${activeAccount.id}/${activeListener.id}`;

               callTokenApi(urlToCall, 'GET', null)
                    .then((response) => {
                         if (response.status === 200) {
                              dispatch(
                                   setEventOptions({
                                        listenerId: activeListener.id,
                                        events: response.data.eventAccounts,
                                   })
                              );
                         }
                    })
                    .finally(() => setFetchEventLoading(false));
          }
     };

     const fetchEventsNative = () => {
          if (activeListener.isNative && activeListener.code === 'nativeDataLayers') {
               setFetchEventLoading(true);
               const urlToCall = `${API_CLIENT_EVENT_NATIVE_ACCOUNT_RULE}${activeAccount.id}/${activeListener.id}`;

               callTokenApi(urlToCall, 'GET', null)
                    .then((response) => {
                         if (response.status === 200) {
                              dispatch(
                                   setEventNativeOptions({
                                        listenerId: activeListener.id,
                                        eventsNative: response.data.eventsNative,
                                   })
                              );
                         }
                    })
                    .finally(() => setFetchEventLoading(false));
          }
     };

     const doFetchCustomVariables = () => {
          if (customVariables.numOfVariables === 0) {
               setFetchCustomVariablesLoading(true);

               dispatch(
                    fetchCustomVariables(activeAccount.id, () => {
                         setFetchCustomVariablesLoading(false);
                    })
               );
          }
     };

     const fetchNativeBrowserStorage = () => {
          if (activeListener.isNative && activeListener.code === 'nativeBrowserStorage') {
               setFetchEventLoading(true);
               const urlToCall = `${API_CLIENT_NATIVE_BROWSER_STORAGE_ACCOUNT_RULE}${activeAccount.id}/${activeListener.id}`;

               callTokenApi(urlToCall, 'GET', null)
                    .then((response) => {
                         if (response.status === 200) {
                              //Flatten multidimensional array
                              const eventsNativeBrowserStorage = [].concat(...response.data.eventsNativeBrowserStorage);
                              dispatch(
                                   setNativeBrowserStorageOptions({
                                        listenerId: activeListener.id,
                                        nativeBrowserStorage: eventsNativeBrowserStorage,
                                   })
                              );
                         }
                    })
                    .finally(() => setFetchEventLoading(false));
          }
     };

     const fetchOptions = () => {
          if (isFetchData) {
               if (fetchOnlyAutomaticVariables) {
                    fetchDatalayerVariables();
               } else {
                    fetchDatalayerVariables();
                    fetchSelectorVariables();
                    fetchEvents();
                    fetchEventsNative();
                    fetchNativeBrowserStorage();
               }

               if (isFetchCustomVariables) {
                    doFetchCustomVariables();
               }
          }
     };

     const reloadVariableOptions = () => {
          if (automaticVariableReload) {
               setFetchVariableLoading(true);
               const urlToCall = `${API_CLIENT_DATALAYER_VARIABLE_RULE}${activeAccount.id}/${activeListener.id}`;

               callTokenApi(urlToCall, 'GET', null)
                    .then((response) => {
                         if (response.status === 200) {
                              dispatch(
                                   setVariableOptions({
                                        listenerId: activeListener.id,
                                        variables: response.data.datalayerVariables,
                                   })
                              );
                         }
                    })
                    .finally(() => {
                         setFetchVariableLoading(false);
                         dispatch(setAutomaticVariableReload(false));
                    });
          }
     };

     // const reloadSelectorOptions = () => {
     //      if (selectorVariableReload) {
     //           setFetchSelectorLoading(true);
     //           const urlToCall = `${API_CLIENT_EVENT_ACCOUNT}${activeAccount.id}/${activeListener.id}`;

     //           callTokenApi(urlToCall, 'GET', null)
     //                .then(response => {
     //                     if (response.status === 200) {
     //                          let selectorSupports = response.data.selectorSupports;
     //                          if (selectorSupports && selectorSupports.length > 0) {
     //                               try {
     //                                    let selectors = JSON.parse(selectorSupports[0].featureSupport) || [];
     //                                    dispatch(setSelectorOptions({
     //                                         listenerId: activeListener.id,
     //                                         variables: selectors
     //                                    }));
     //                               } catch (error) {
     //                                    console.log(error);
     //                               }
     //                          }
     //                     }
     //                })
     //                .finally(() => {
     //                     setFetchSelectorLoading(false);
     //                     dispatch(setSelectorVariableReload(false));
     //                })
     //      }
     // }

     useEffect(fetchOptions, [isFetchData]); // eslint-disable-line react-hooks/exhaustive-deps
     useEffect(reloadVariableOptions, [automaticVariableReload]); // eslint-disable-line react-hooks/exhaustive-deps
     // useEffect(reloadSelectorOptions, [selectorVariableReload]);

     return {
          fetchLoading,
          fetchVariableLoading,
          variables,
          events,
          eventsNative,
          nativeBrowserStorage,
          customVariableOptions,
          isSelectorFeatureOpen,
          reactSelectEventOptions,
          reactSelectVariableOptions,
          reactSelectEventNativeOptions,
          reactSelectCustomVariableOptions,
          reactSelectVariableValueOptions,
          reactSelectNativeBrowserStorageOptions,
          reactSelectSelectorValueOptions,
     };
};

export const useEventAndVariableOptionsV2 = ({ isFetchData, fetchOnlyAutomaticVariables, isFetchCustomVariables, activeListener }) => {
     const dispatch = useDispatch();
     const [fetchVariableLoading, setFetchVariableLoading] = useState(false);
     const [fetchEventLoading, setFetchEventLoading] = useState(false);
     const [fetchCustomVariablesLoading, setFetchCustomVariablesLoading] = useState(false);
     const [fetchSelectorLoading, setFetchSelectorLoading] = useState(false);
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const variableOptions = useSelector((state) => state.subscriber.variableOptions);
     const eventOptions = useSelector((state) => state.subscriber.eventOptions);
     const eventNativeOptions = useSelector((state) => state.subscriber.eventNativeOptions);
     const customVariables = useSelector((state) => state.subscriber.customVariables);
     const customVariableOptions = useSelector((state) => state.subscriber.customVariableOptions);
     const nativeBrowserStorageOptions = useSelector((state) => state.subscriber.nativeBrowserStorageOptions);
     const selectorOptions = useSelector((state) => state.subscriber.selectorOptions);
     const automaticVariableReload = useSelector((state) => state.subscriber.automaticVariableReload);
     const activeFeatures = useSelector((state) => state.subscriber.activeFeatures);
     let fetchLoading = fetchVariableLoading || fetchEventLoading || fetchCustomVariablesLoading || fetchSelectorLoading;
     const { listenerId: variableListenerId, variables } = variableOptions;
     const { listenerId: selectorListenerId, variables: selectorVariables } = selectorOptions;
     const { listenerId: eventListenerId, events } = eventOptions;
     const { eventsNative } = eventNativeOptions;
     const { nativeBrowserStorage } = nativeBrowserStorageOptions;
     function capitalizeFirstLetter(string) {
          return string[0].toUpperCase() + string.slice(1);
     }
     // Automatic variable options for react-select
     let reactSelectVariableOptions = variables.map((variableOption) => {
          let parentName = variableOption.friendlyName || variableOption.fullName;
          let variableOptionValue = JSON.stringify({ id: variableOption.id, key: variableOption.fullName });

          return {
               value: variableOptionValue,
               label: parentName,
          };
     });

     // Event options for react-select
     let reactSelectEventOptions = events.map((eventOption) => ({
          value: JSON.stringify({ id: eventOption.id, key: eventOption.code }),
          label: eventOption.friendlyName || eventOption.code,
     }));

     // Event Native options for react-select

     let reactSelectEventNativeOptions = eventsNative.map((eventOption) => ({
          value: JSON.stringify({ id: eventOption, key: eventOption }),
          label: eventOption,
     }));

     // Automatic variable options for react-select limit value - POPULATE
     const _datas = [];
     variables.forEach((variableOption) => {
          let textToCompare;

          if (variableOption.fullName.includes('.name') && !variableOption.fullName.includes('.names[')) {
               textToCompare = 'name';
          } else if (variableOption.fullName.includes('.id')) {
               textToCompare = 'id';
          }

          if (textToCompare) {
               let parentId = variableOption.fullName.split('.');

               if (parentId[2]) {
                    if (typeof parentId[3] === 'string' && parentId[3] !== textToCompare && parentId[2] !== 'name') {
                         _datas.push(`${parentId[2]}.${parentId[3]}`);
                    } else {
                         _datas.push(parentId[2]);
                    }
               }
          }
     });

     let noDuplicateValues = new Set(_datas); // Remove duplicate values
     let reactSelectVariableValueOptions = Array.from(noDuplicateValues).map((val) => ({ value: val, label: val }));
     let activeSelectSelectorValueOptions = selectorVariables.filter((item) => item.status === true);
     let reactSelectSelectorValueOptions = activeSelectSelectorValueOptions.map((option) => ({
          id: option.id,
          friendlyName: option.friendlyName,
          value: JSON.stringify({ id: option.id, key: option.fullName }),
          label: option.friendlyName || option.fullName,
     }));

     // Native Browser Storage options for react-select
     let reactSelectNativeBrowserStorageOptions = nativeBrowserStorage.map((eventOption) => ({
          id: eventOption.nativeId,
          value: JSON.stringify({ id: eventOption, key: eventOption }),
          label: `${capitalizeFirstLetter(eventOption.type)}: ${eventOption.name}, Format: ${capitalizeFirstLetter(eventOption.format)}`,
     }));

     // Custom variable options for react-select
     let reactSelectCustomVariableOptions = customVariableOptions.map((customVariable) => ({
          value: customVariable.id,
          label: customVariable.name,
     }));

     let isSelectorFeatureOpen = activeFeatures.some((feature) => (feature.featureType === 'selector' || feature.type === 'selector') && feature.listenerId === activeListener.id);

     const fetchDatalayerVariables = () => {
          if (!variableListenerId || variableListenerId !== activeListener.id) {
               setFetchVariableLoading(true);
               const urlToCall = `${API_CLIENT_DATALAYER_VARIABLE_RULE}${activeAccount.id}/${activeListener.id}`;

               callTokenApi(urlToCall, 'GET', null)
                    .then((response) => {
                         if (response.status === 200) {
                              dispatch(
                                   setVariableOptions({
                                        listenerId: activeListener.id,
                                        variables: response.data.datalayerVariables,
                                   })
                              );
                         }
                    })
                    .finally(() => setFetchVariableLoading(false));
          }
     };

     const fetchSelectorVariables = async () => {
          if (!selectorListenerId || selectorListenerId !== activeListener.id) {
               setFetchSelectorLoading(true);
               const urlToCall = `${API_CLIENT_SELECTOR_VARIABLE_RULE}${activeAccount.id}/${activeListener.id}`;

               callTokenApi(urlToCall, 'GET', null)
                    .then((response) => {
                         if (response.status === 200) {
                              dispatch(
                                   setSelectorOptions({
                                        listenerId: activeListener.id,
                                        variables: response.data.selectorVariables,
                                   })
                              );
                         }
                    })
                    .finally(() => setFetchSelectorLoading(false));
          }
     };

     const fetchEvents = () => {
          if (!eventListenerId || eventListenerId !== activeListener.id) {
               setFetchEventLoading(true);
               const urlToCall = `${API_CLIENT_EVENT_ACCOUNT_RULE}${activeAccount.id}/${activeListener.id}`;

               callTokenApi(urlToCall, 'GET', null)
                    .then((response) => {
                         if (response.status === 200) {
                              dispatch(
                                   setEventOptions({
                                        listenerId: activeListener.id,
                                        events: response.data.eventAccounts,
                                   })
                              );
                         }
                    })
                    .finally(() => setFetchEventLoading(false));
          }
     };

     const fetchEventsNative = () => {
          if (activeListener.isNative && activeListener.code === 'nativeDataLayers') {
               setFetchEventLoading(true);
               const urlToCall = `${API_CLIENT_EVENT_NATIVE_ACCOUNT_RULE}${activeAccount.id}/${activeListener.id}`;

               callTokenApi(urlToCall, 'GET', null)
                    .then((response) => {
                         if (response.status === 200) {
                              dispatch(
                                   setEventNativeOptions({
                                        listenerId: activeListener.id,
                                        eventsNative: response.data.eventsNative,
                                   })
                              );
                         }
                    })
                    .finally(() => setFetchEventLoading(false));
          }
     };

     const doFetchCustomVariables = () => {
          if (customVariables.numOfVariables === 0) {
               setFetchCustomVariablesLoading(true);

               dispatch(
                    fetchCustomVariables(activeAccount.id, () => {
                         setFetchCustomVariablesLoading(false);
                    })
               );
          }
     };

     const fetchNativeBrowserStorage = () => {
          if (activeListener.isNative && activeListener.code === 'nativeBrowserStorage') {
               setFetchEventLoading(true);
               const urlToCall = `${API_CLIENT_NATIVE_BROWSER_STORAGE_ACCOUNT_RULE}${activeAccount.id}/${activeListener.id}`;

               callTokenApi(urlToCall, 'GET', null)
                    .then((response) => {
                         if (response.status === 200) {
                              //Flatten multidimensional array
                              const eventsNativeBrowserStorage = [].concat(...response.data.eventsNativeBrowserStorage);
                              dispatch(
                                   setNativeBrowserStorageOptions({
                                        listenerId: activeListener.id,
                                        nativeBrowserStorage: eventsNativeBrowserStorage,
                                   })
                              );
                         }
                    })
                    .finally(() => setFetchEventLoading(false));
          }
     };

     const fetchOptions = () => {
          if (isFetchData) {
               if (fetchOnlyAutomaticVariables) {
                    fetchDatalayerVariables();
               } else {
                    fetchDatalayerVariables();
                    fetchSelectorVariables();
                    fetchEvents();
                    fetchEventsNative();
                    fetchNativeBrowserStorage();
               }

               if (isFetchCustomVariables) {
                    doFetchCustomVariables();
               }
          }
     };

     const reloadVariableOptions = () => {
          if (automaticVariableReload) {
               setFetchVariableLoading(true);
               const urlToCall = `${API_CLIENT_DATALAYER_VARIABLE_RULE}${activeAccount.id}/${activeListener.id}`;

               callTokenApi(urlToCall, 'GET', null)
                    .then((response) => {
                         if (response.status === 200) {
                              dispatch(
                                   setVariableOptions({
                                        listenerId: activeListener.id,
                                        variables: response.data.datalayerVariables,
                                   })
                              );
                         }
                    })
                    .finally(() => {
                         setFetchVariableLoading(false);
                         dispatch(setAutomaticVariableReload(false));
                    });
          }
     };

     useEffect(fetchOptions, [isFetchData]); // eslint-disable-line react-hooks/exhaustive-deps
     useEffect(reloadVariableOptions, [automaticVariableReload]); // eslint-disable-line react-hooks/exhaustive-deps

     return {
          fetchLoading,
          fetchVariableLoading,
          variables,
          events,
          eventsNative,
          nativeBrowserStorage,
          customVariableOptions,
          isSelectorFeatureOpen,
          reactSelectEventOptions,
          reactSelectVariableOptions,
          reactSelectEventNativeOptions,
          reactSelectCustomVariableOptions,
          reactSelectVariableValueOptions,
          reactSelectNativeBrowserStorageOptions,
          reactSelectSelectorValueOptions,
     };
};

export const usePreviewEventAndVariableOptions = ({ isFetchData, fetchOnlyAutomaticVariables, isFetchCustomVariables, activeListener }) => {
     const dispatch = useDispatch();
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const { eventOptions, variableOptions, selectorOptions, eventNativeOptions, nativeBrowserStorageOptions } = useSelector((state) => state.subscriber.previewOptions);
     const customVariableOptions = useSelector((state) => state.subscriber.customVariableOptions);
     const activeFeatures = useSelector((state) => state.subscriber.activeFeatures);
     const customVariables = useSelector((state) => state.subscriber.customVariables);
     const automaticVariableReload = useSelector((state) => state.subscriber.automaticVariableReload);
     const { eventsNative } = eventNativeOptions;
     const { listenerId: eventListenerId, events } = eventOptions;
     const { nativeBrowserStorage } = nativeBrowserStorageOptions;
     const { listenerId: variableListenerId, variables } = variableOptions;
     const { listenerId: selectorListenerId, variables: selectorVariables } = selectorOptions;

     const [fetchEventLoading, setFetchEventLoading] = useState(false);
     const [fetchVariableLoading, setFetchVariableLoading] = useState(false);
     const [fetchSelectorLoading, setFetchSelectorLoading] = useState(false);
     const [fetchCustomVariablesLoading, setFetchCustomVariablesLoading] = useState(false);

     let fetchLoading = fetchVariableLoading || fetchEventLoading || fetchCustomVariablesLoading || fetchSelectorLoading;

     function capitalizeFirstLetter(string) {
          return string[0].toUpperCase() + string.slice(1);
     }

     // Automatic variable options for react-select
     let reactSelectVariableOptions = variables.map((variableOption) => {
          let parentName = variableOption.friendlyName || variableOption.fullName;
          let variableOptionValue = JSON.stringify({ id: variableOption.id, key: variableOption.fullName });

          return {
               value: variableOptionValue,
               label: parentName,
          };
     });

     // Event options for react-select
     let reactSelectEventOptions = events.map((eventOption) => ({
          value: JSON.stringify({ id: eventOption.id, key: eventOption.code }),
          label: eventOption.friendlyName || eventOption.code,
     }));

     // Event Native options for react-select

     let reactSelectEventNativeOptions = eventsNative.map((eventOption) => ({
          value: JSON.stringify({ id: eventOption, key: eventOption }),
          label: eventOption,
     }));

     // Automatic variable options for react-select limit value - POPULATE
     const _datas = [];
     variables.forEach((variableOption) => {
          let textToCompare;

          if (variableOption.fullName.includes('.name') && !variableOption.fullName.includes('.names[')) {
               textToCompare = 'name';
          } else if (variableOption.fullName.includes('.id')) {
               textToCompare = 'id';
          }

          if (textToCompare) {
               let parentId = variableOption.fullName.split('.');

               if (parentId[2]) {
                    if (typeof parentId[3] === 'string' && parentId[3] !== textToCompare && parentId[2] !== 'name') {
                         _datas.push(`${parentId[2]}.${parentId[3]}`);
                    } else {
                         _datas.push(parentId[2]);
                    }
               }
          }
     });

     let noDuplicateValues = new Set(_datas); // Remove duplicate values
     let reactSelectVariableValueOptions = Array.from(noDuplicateValues).map((val) => ({ value: val, label: val }));

     let reactSelectSelectorValueOptions = selectorVariables.map((option) => ({
          id: option.id,
          friendlyName: option.friendlyName,
          value: JSON.stringify({ id: option.id, key: option.fullName }),
          label: option.friendlyName || option.fullName,
     }));

     // Native Browser Storage options for react-select
     let reactSelectNativeBrowserStorageOptions = nativeBrowserStorage.map((eventOption) => ({
          id: eventOption.nativeId,
          value: JSON.stringify({ id: eventOption, key: eventOption }),
          label: `${capitalizeFirstLetter(eventOption.type)}: ${eventOption.name}, Format: ${capitalizeFirstLetter(eventOption.format)}`,
     }));

     // Custom variable options for react-select
     let reactSelectCustomVariableOptions = customVariableOptions.map((customVariable) => ({
          value: customVariable.id,
          label: customVariable.name,
     }));

     let isSelectorFeatureOpen = activeFeatures.some((feature) => (feature.featureType === 'selector' || feature.type === 'selector') && feature.listenerId === activeListener.id);

     const fetchDatalayerVariables = () => {
          if (!variableListenerId || variableListenerId !== activeListener.id || variableOptions.variables.length === 0) {
               setFetchVariableLoading(true);
               const urlToCall = `${API_CLIENT_DATALAYER_VARIABLE_RULE}${activeAccount.id}/${activeListener.id}`;

               callTokenApi(urlToCall, 'GET', null)
                    .then((response) => {
                         if (response.status === 200) {
                              dispatch(
                                   setPreviewOptions({
                                        variableOptions: {
                                             listenerId: activeListener.id,
                                             variables: response.data.datalayerVariables,
                                        },
                                   })
                              );
                         }
                    })
                    .finally(() => setFetchVariableLoading(false));
          }
     };

     const fetchSelectorVariables = async () => {
          if (!selectorListenerId || selectorListenerId !== activeListener.id) {
               setFetchSelectorLoading(true);
               const urlToCall = `${API_CLIENT_SELECTOR_VARIABLE_RULE}${activeAccount.id}/${activeListener.id}`;

               callTokenApi(urlToCall, 'GET', null)
                    .then((response) => {
                         if (response.status === 200) {
                              dispatch(
                                   setPreviewOptions({
                                        selectorOptions: {
                                             listenerId: activeListener.id,
                                             variables: response.data.selectorVariables,
                                        },
                                   })
                              );
                         }
                    })
                    .finally(() => setFetchSelectorLoading(false));
          }
     };

     const fetchEvents = () => {
          if (!eventListenerId || eventListenerId !== activeListener.id) {
               setFetchEventLoading(true);
               const urlToCall = `${API_CLIENT_EVENT_ACCOUNT_RULE}${activeAccount.id}/${activeListener.id}`;

               callTokenApi(urlToCall, 'GET', null)
                    .then((response) => {
                         if (response.status === 200) {
                              dispatch(
                                   setPreviewOptions({
                                        eventOptions: {
                                             listenerId: activeListener.id,
                                             events: response.data.eventAccounts,
                                        },
                                   })
                              );
                         }
                    })
                    .finally(() => setFetchEventLoading(false));
          }
     };

     const fetchEventsNative = () => {
          if (activeListener.isNative && activeListener.code === 'nativeDataLayers') {
               setFetchEventLoading(true);
               const urlToCall = `${API_CLIENT_EVENT_NATIVE_ACCOUNT_RULE}${activeAccount.id}/${activeListener.id}`;

               callTokenApi(urlToCall, 'GET', null)
                    .then((response) => {
                         if (response.status === 200) {
                              dispatch(
                                   setPreviewOptions({
                                        eventNativeOptions: {
                                             listenerId: activeListener.id,
                                             eventsNative: response.data.eventsNative,
                                        },
                                   })
                              );
                         }
                    })
                    .finally(() => setFetchEventLoading(false));
          }
     };

     const fetchNativeBrowserStorage = () => {
          if (activeListener.isNative && activeListener.code === 'nativeBrowserStorage') {
               setFetchEventLoading(true);
               const urlToCall = `${API_CLIENT_NATIVE_BROWSER_STORAGE_ACCOUNT_RULE}${activeAccount.id}/${activeListener.id}`;

               callTokenApi(urlToCall, 'GET', null)
                    .then((response) => {
                         if (response.status === 200) {
                              const eventsNativeBrowserStorage = [].concat(...response.data.eventsNativeBrowserStorage);
                              dispatch(
                                   setPreviewOptions({
                                        nativeBrowserStorageOptions: {
                                             listenerId: activeListener.id,
                                             nativeBrowserStorage: eventsNativeBrowserStorage,
                                        },
                                   })
                              );
                         }
                    })
                    .finally(() => setFetchEventLoading(false));
          }
     };

     const doFetchCustomVariables = () => {
          if (customVariables.numOfVariables === 0) {
               setFetchCustomVariablesLoading(true);

               dispatch(
                    fetchCustomVariables(activeAccount.id, () => {
                         setFetchCustomVariablesLoading(false);
                    })
               );
          }
     };

     const fetchOptions = () => {
          if (isFetchData) {
               if (fetchOnlyAutomaticVariables) {
                    fetchDatalayerVariables();
               } else {
                    fetchDatalayerVariables();
                    fetchSelectorVariables();
                    fetchEvents();
                    fetchEventsNative();
                    fetchNativeBrowserStorage();
               }

               if (isFetchCustomVariables) {
                    doFetchCustomVariables();
               }
          }
     };

     const reloadVariableOptions = () => {
          if (automaticVariableReload) {
               setFetchVariableLoading(true);
               const urlToCall = `${API_CLIENT_DATALAYER_VARIABLE_RULE}${activeAccount.id}/${activeListener.id}`;

               callTokenApi(urlToCall, 'GET', null)
                    .then((response) => {
                         if (response.status === 200) {
                              dispatch(
                                   setPreviewOptions({
                                        variableOptions: {
                                             listenerId: activeListener.id,
                                             variables: response.data.datalayerVariables,
                                        },
                                   })
                              );
                         }
                    })
                    .finally(() => {
                         setFetchVariableLoading(false);
                         dispatch(setAutomaticVariableReload(false));
                    });
          }
     };

     useEffect(fetchOptions, [isFetchData]); // eslint-disable-line react-hooks/exhaustive-deps
     useEffect(reloadVariableOptions, [automaticVariableReload]); // eslint-disable-line react-hooks/exhaustive-deps

     return {
          fetchLoading,
          fetchVariableLoading,
          variables,
          events,
          eventsNative,
          nativeBrowserStorage,
          customVariableOptions,
          isSelectorFeatureOpen,
          reactSelectEventOptions,
          reactSelectVariableOptions,
          reactSelectEventNativeOptions,
          reactSelectCustomVariableOptions,
          reactSelectVariableValueOptions,
          reactSelectNativeBrowserStorageOptions,
          reactSelectSelectorValueOptions,
     };
};

export const useTriggersNameAccount = (isFetchData = '', findTriggers = [], findConversions = []) => {
     const dispatch = useDispatch();
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const triggerRules = useSelector((state) => state.subscriber.triggerRules);
     const conversionRules = useSelector((state) => state.subscriber.conversionRules);
     const { triggersName } = useSelector((state) => state.subscriber.triggersNameOptions);
     const { conversionsName } = useSelector((state) => state.subscriber.conversionsNameOptions);
     const listenerEventsNative = useSelector((state) => state.subscriber.listenerEventsNative);
     const listBlockTriggersRule = useSelector((state) => state.subscriber.listBlockTriggers);
     const destinationId = useSelector((state) => state.subscriber.dataActions.destinationId);

     const eventECommerce = listenerEventsNative.listenerEventsNativeChange ? listenerEventsNative.listenerEventsNativeChange.filter((item) => item.eCommerceData) : []

     const triggersFinded = triggersName.filter((rule) => findTriggers.includes(rule.value));
     const conversionsFinded = conversionsName.filter((rule) => findConversions.includes(rule.value));

     const getTriggersName = (rules) => {
          const _triggersName = [];
          rules.forEach((rule) => {
               if (rule.data.key === 'pageRequested') return;

               if (rule.type === 'simple') {
                    if (rule.status && Array.isArray(rule.conditions) && rule.conditions[0].g0 && rule.conditions[0].g0.key !== '') {
                         _triggersName.push({ value: rule.id, label: rule.name, badge: 'trigger' });
                    }
               } else {
                    if (rule.status && rule.data && Array.isArray(rule.data.triggers) && rule.conditions && rule.conditions.length > 0) {
                         const triggers = rule.data.triggers;

                         if (rule.name === 'Ecommerce Events' && !rule.isCustom) {
                              const conditions = rule.conditions;

                              const activeEcommerceEvents = (conditions[0].length > 1 && conditions.length > 1) ? conditions[0].map((con) => con.g0.key) : conditions.map((con) => con[0].g0.key);
                              const activeEcommerceTriggers = [];

                              activeEcommerceEvents.forEach((event) => {
                                   let trigger;

                                   if (activeAccount.accountSync) {
                                        trigger= triggers.find((el) => el.eventName === CUSTOM_EVENT_NAMES[event]);
                                   } else {
                                        trigger= triggers.find((el) => el.eventName === CUSTOM_EVENT_NAMES_V2[event]);
                                   }

                                   if(!trigger){
                                        const currentEvent = eventECommerce.find((_item) => _item.value === event)
                                        if(currentEvent) {
                                             trigger= triggers.find((el) => el.eventName === currentEvent.eCommerceDataValue
                                             );
                                        }
                                   }

                                   if (trigger) {
                                        activeEcommerceTriggers.push(trigger.name);
                                   }
                              });

                              triggers.forEach((r) => {
                                   if (activeEcommerceTriggers.some((el) => el === r.name)) {
                                        if (isFetchData === 'fetch-trigger-ecommerce') {
                                             _triggersName.push({ value: rule.id, label: r.name, badge: 'trigger' });
                                        } else {
                                             _triggersName.push({ value: r.id, label: r.name, badge: 'trigger' });
                                        }
                                   }
                              });
                         } else {
                              triggers.forEach((r) => {
                                   if (isFetchData === 'fetch-trigger-ecommerce') {
                                        _triggersName.push({ value: rule.id, label: r.name, badge: 'trigger' });
                                   } else {
                                        _triggersName.push({ value: r.id, label: r.name, badge: 'trigger' });
                                   }
                              });
                         }
                    }
               }
          });
          return _triggersName;
     };

     const getConversionsName = (rules) => {
          const _conversionsName = [];
          rules.forEach((rule) => {
               if (rule.data.key === 'pageRequested' || (rule.data.slug && rule.data.slug.toLowerCase() === 'purchase')) return;

               if (rule.type === 'simple') {
                    if (rule.status && Array.isArray(rule.conditions) && rule.conditions[0].g0) {
                         _conversionsName.push({ value: rule.id, label: rule.name, badge: 'conversion trigger' });
                    }
               } else {
                    if (rule.status && rule.conditions && rule.conditions.length > 0 && rule.customVariables && Array.isArray(rule.customVariables) && rule.customVariables.length > 0) {
                         const conversions = rule.customVariables;

                         if (rule.name === 'Ecommerce Events' && !rule.isCustom) {
                              const conditions = rule.conditions;

                              const activeEcommerceEvents = (conditions[0].length > 1 && conditions.length > 1) ? conditions[0].map((con) => con.g0.key) : conditions.map((con) => con[0].g0.key);
                              const activeEcommerceconversions = [];

                              activeEcommerceEvents.forEach((event) => {
                                   let trigger;

                                   if (activeAccount.accountSync) {
                                        trigger= conversions.find((el) => el.eventName === CUSTOM_EVENT_NAMES[event]);
                                   } else {
                                        trigger= conversions.find((el) => el.eventName === CUSTOM_EVENT_NAMES_V2[event]);
                                   }

                                   if(!trigger){
                                        const currentEvent = eventECommerce.find((_item) => _item.value === event)
                                        if(currentEvent) {
                                             trigger= conversions.find((el) => el.eventName === currentEvent.eCommerceDataValue
                                             );
                                        }
                                   }

                                   if (trigger) {
                                        activeEcommerceconversions.push(trigger.name);
                                   }
                              });

                              conversions.forEach((r) => {
                                   if (activeEcommerceconversions.some((el) => el === r.name)) {
                                        if (isFetchData === 'fetch-trigger-ecommerce') {
                                             _conversionsName.push({ value: rule.id, label: r.name, badge: 'conversion trigger' });
                                        } else {
                                             _conversionsName.push({ value: r.id, label: r.name, badge: 'conversion trigger' });
                                        }
                                   }
                              });
                         } else {
                              conversions.forEach((r) => {
                                   if (isFetchData === 'fetch-trigger-ecommerce') {
                                        _conversionsName.push({ value: rule.id, label: r.name, badge: 'conversion trigger' });
                                   } else if(r[0].name && r[0].id) {
                                        _conversionsName.push({ value: r[0].id, label: r[0].name, badge: 'conversion trigger' });
                                   }
                              });
                         }
                    }
               }
          });
          return _conversionsName;
     };

     const fetchTriggers = () => {
          if (triggerRules.length === 0) {
               callTokenApi(`${API_CLIENT_ACCOUNT_RULES}/${activeAccount.id}?group=triggers`, 'GET')
                    .then((response) => {
                         if (response.status === 200) {
                              const { rules } = response.data;

                              dispatch(setTriggersName({ ruleId: isFetchData, triggersName: getTriggersName(rules) }));
                              dispatch(setTriggerRules(rules));
                              dispatch(setInitialTriggerRules(deepCopyArray(rules)));
                         } else {
                              toastError(response);
                         }
                    })
                    
          } else {
               dispatch(setTriggersName({ ruleId: isFetchData, triggersName: getTriggersName(triggerRules) }));
          }
     };

     const fetchConversions = () => {
          if(conversionRules.length === 0){
               callTokenApi(`${API_CLIENT_ACCOUNT_RULES}/${activeAccount.id}?group=conversions`, 'GET')
               .then((response) => {
                    if (response.status === 200) {
                         const { rules } = response.data;
                         dispatch(setConversionsName({ ruleId: isFetchData, conversionsName: getConversionsName(rules) }));
                         dispatch(setConversionRules(rules));
                         dispatch(setInitialConversionRules(deepCopyArray(rules)));
                    } else {
                         toastError(response);
                    }
               })
          } else {
               dispatch(setConversionsName({ ruleId: isFetchData, conversionsName: getConversionsName(conversionRules) }));
          }
     }

     const fetchBlockTriggers = () => {
          if(listBlockTriggersRule.length === 0){
               callTokenApi(`${API_CLIENT_ACCOUNT_RULES}/${activeAccount.id}?group=blockTrigger&destinationId=${destinationId}`, 'GET')
               .then((response) => {
                    if (response.status === 200) {
                         const { rules } = response.data;
                         
                         dispatch(setBlockTriggerRules([...rules]))
                    } else {
                         toastError(response);
                    }
               })
          } else {
               dispatch(setBlockTriggerRules([...listBlockTriggersRule]));
          }
     }
     
     const fetRulesTriggerConversion = () => {
          fetchTriggers()
          fetchConversions()
          fetchBlockTriggers()
     }

     useEffect(fetRulesTriggerConversion, []); // eslint-disable-line

     return {
          triggersName,
          triggersFinded,
          conversionsName,
          conversionsFinded,
          listBlockTriggersRule,
     };
};

export const useEventAndVariableAccountOptions = (isFetchData, fetchOnlyAutomaticVariables, isFetchCustomVariables) => {
     const dispatch = useDispatch();
     const [fetchVariableLoading, setFetchVariableLoading] = useState(false);
     const [fetchEventLoading, setFetchEventLoading] = useState(false);
     const [fetchCustomVariablesLoading, setFetchCustomVariablesLoading] = useState(false);
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const variableOptions = useSelector((state) => state.subscriber.variableOptions); // This is automatic variable
     const eventOptions = useSelector((state) => state.subscriber.eventOptions);
     const customVariables = useSelector((state) => state.subscriber.customVariables);
     const customVariableOptions = useSelector((state) => state.subscriber.customVariableOptions);
     const nativeBrowserStorageOptions = useSelector((state) => state.subscriber.nativeBrowserStorageOptions);
     const automaticVariableReload = useSelector((state) => state.subscriber.automaticVariableReload);
     const activeListeners = useSelector((state) => state.subscriber.activeListeners);
     const eventNativeOptions = useSelector((state) => state.subscriber.eventNativeOptions);
     let fetchLoading = fetchVariableLoading || fetchEventLoading || fetchCustomVariablesLoading;
     const { listenerId: variableListenerId, variables } = variableOptions;
     const { listenerId: eventListenerId, events } = eventOptions;
     const { nativeBrowserStorage } = nativeBrowserStorageOptions;
     const { eventsNative } = eventNativeOptions;
     function capitalizeFirstLetter(string) {
          return string[0].toUpperCase() + string.slice(1);
     }
     // Automatic variable options for react-select
     let reactSelectVariableOptions = variables.map((variableOption) => {
          let parentName = variableOption.friendlyName || variableOption.fullName;
          let variableOptionValue = JSON.stringify({ id: variableOption.id, key: variableOption.fullName });

          return {
               value: variableOptionValue,
               label: parentName,
          };
     });

     // Event options for react-select
     let reactSelectEventOptions = events.map((eventOption) => ({
          value: JSON.stringify({ id: eventOption.id, key: eventOption.code }),
          label: eventOption.code,
     }));

     // Automatic variable options for react-select limit value - POPULATE
     const _datas = [],
          formRuleVariableOptions = [];

     variables.forEach((variableOption) => {
          let textToCompare;

          if (variableOption.fullName.includes('.name') && !variableOption.fullName.includes('.names[')) {
               textToCompare = 'name';
          } else if (variableOption.fullName.includes('.id')) {
               textToCompare = 'id';
          }

          if (textToCompare) {
               let parentId = variableOption.fullName.split('.');

               if (parentId[2]) {
                    if (typeof parentId[3] === 'string' && parentId[3] !== textToCompare && parentId[2] !== 'name') {
                         _datas.push(`${parentId[2]}.${parentId[3]}`);
                    } else {
                         _datas.push(parentId[2]);
                    }
               }
          }

          if (variableOption.fullName && variableOption.fullName.includes('formAutomaticValues')) {
               formRuleVariableOptions.push(variableOption);
          }
     });

     let noDuplicateValues = new Set(_datas); // Remove duplicate values
     let reactSelectVariableValueOptions = Array.from(noDuplicateValues).map((val) => ({ value: val, label: val }));

     // Native Browser Storage options for react-select
     let reactSelectNativeBrowserStorageOptions = nativeBrowserStorage.map((eventOption) => ({
          id: eventOption.nativeId,
          value: JSON.stringify({ id: eventOption, key: eventOption }),
          label: `${capitalizeFirstLetter(eventOption.type)}: ${eventOption.name}, Format: ${capitalizeFirstLetter(eventOption.format)}`,
     }));

     // Custom variable options for react-select
     let reactSelectCustomVariableOptions = customVariableOptions.map((customVariable) => ({
          value: customVariable.id,
          label: customVariable.name,
     }));

     const _nativeBrowserStorage = activeListeners.find((listener) => listener.code === 'nativeBrowserStorage');
     const nativeBrowserStorageOpen = _nativeBrowserStorage ? true : false;

     const fetchDatalayerVariables = () => {
          if (!variableListenerId || variableListenerId !== activeAccount.id) {
               setFetchVariableLoading(true);
               const urlToCall = `${API_CLIENT_DATALAYER_VARIABLE_RULE}${activeAccount.id}`;

               callTokenApi(urlToCall, 'GET', null)
                    .then((response) => {
                         if (response.status === 200) {
                              dispatch(
                                   setVariableOptions({
                                        listenerId: activeAccount.id,
                                        variables: response.data.datalayerVariables,
                                   })
                              );
                         }
                    })
                    .finally(() => setFetchVariableLoading(false));
          }
     };

     const fetchEvents = () => {
          if (!eventListenerId || eventListenerId !== activeAccount.id) {
               setFetchEventLoading(true);
               const urlToCall = `${API_CLIENT_EVENT_ACCOUNT_RULE}${activeAccount.id}`;

               callTokenApi(urlToCall, 'GET', null)
                    .then((response) => {
                         if (response.status === 200) {
                              dispatch(
                                   setEventOptions({
                                        listenerId: activeAccount.id,
                                        events: response.data.eventAccounts,
                                   })
                              );
                         }
                    })
                    .finally(() => setFetchEventLoading(false));
          }
     };

     const doFetchCustomVariables = () => {
          if (customVariables.numOfVariables === 0) {
               setFetchCustomVariablesLoading(true);

               dispatch(
                    fetchCustomVariables(activeAccount.id, () => {
                         setFetchCustomVariablesLoading(false);
                    })
               );
          }
     };

     const fetchNativeBrowserStorage = () => {
          if (nativeBrowserStorageOpen && (!eventListenerId || eventListenerId !== _nativeBrowserStorage.id)) {
               setFetchEventLoading(true);
               const urlToCall = `${API_CLIENT_NATIVE_BROWSER_STORAGE_ACCOUNT_RULE}${activeAccount.id}/${_nativeBrowserStorage.id}`;

               callTokenApi(urlToCall, 'GET', null)
                    .then((response) => {
                         if (response.status === 200) {
                              //Flatten multidimensional array
                              const eventsNativeBrowserStorage = [].concat(...response.data.eventsNativeBrowserStorage);
                              dispatch(
                                   setNativeBrowserStorageOptions({
                                        listenerId: _nativeBrowserStorage.id,
                                        nativeBrowserStorage: eventsNativeBrowserStorage,
                                   })
                              );
                         }
                    })
                    .finally(() => setFetchEventLoading(false));
          }
     };

     const fetchOptions = () => {
          if (isFetchData) {
               if (fetchOnlyAutomaticVariables) {
                    fetchDatalayerVariables();
               } else {
                    fetchDatalayerVariables();
                    fetchEvents();
                    fetchNativeBrowserStorage();
               }

               if (isFetchCustomVariables) {
                    doFetchCustomVariables();
               }
          }
     };

     const reloadVariableOptions = () => {
          if (automaticVariableReload) {
               setFetchVariableLoading(true);
               const urlToCall = `${API_CLIENT_DATALAYER_VARIABLE_RULE}${activeAccount.id}`;

               callTokenApi(urlToCall, 'GET', null)
                    .then((response) => {
                         if (response.status === 200) {
                              dispatch(
                                   setVariableOptions({
                                        listenerId: activeAccount.id,
                                        variables: response.data.datalayerVariables,
                                   })
                              );
                         }
                    })
                    .finally(() => setFetchVariableLoading(false));
          }
     };

     useEffect(fetchOptions, [isFetchData]); // eslint-disable-line react-hooks/exhaustive-deps
     useEffect(reloadVariableOptions, [automaticVariableReload]); // eslint-disable-line react-hooks/exhaustive-deps

     return {
          fetchLoading,
          fetchVariableLoading,
          variables,
          events,
          eventsNative,
          nativeBrowserStorage,
          customVariableOptions,
          reactSelectEventOptions,
          reactSelectVariableOptions,
          reactSelectCustomVariableOptions,
          reactSelectVariableValueOptions,
          reactSelectNativeBrowserStorageOptions,
          nativeBrowserStorageOpen,
          formRuleVariableOptions,
     };
};

export const useEventAndVariableOptionsForCustomDimension = ({ isFetchData }) => {
     const dispatch = useDispatch();
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const eventOptions = useSelector((state) => state.subscriber.eventOptions);
     const variableOptions = useSelector((state) => state.subscriber.variableOptions);
     const automaticVariableReload = useSelector((state) => state.subscriber.automaticVariableReload);
     const { listenerId: variableListenerId, variables } = variableOptions;
     const { listenerId: eventListenerId, events } = eventOptions;

     const reactSelectEventOptions = events.map((eventOption) => ({
          value: JSON.stringify({ id: eventOption.id, key: eventOption.code }),
          label: eventOption.friendlyName || eventOption.code,
     }));
     const reactSelectVariableOptions = variables.map((variableOption) => {
          const parentName = variableOption.friendlyName || variableOption.fullName;
          const variableOptionValue = JSON.stringify({ id: variableOption.id, key: variableOption.fullName });
          return {
               value: variableOptionValue,
               label: parentName,
               listenerId: variableOption.listenerId,
          };
     });

     const [fetchEventLoading, setFetchEventLoading] = useState(false);
     const [fetchVariableLoading, setFetchVariableLoading] = useState(false);

     const fetchDatalayerVariables = () => {
          if (!variableListenerId || variableListenerId !== activeAccount.id) {
               setFetchVariableLoading(true);
               const urlToCall = `${API_CLIENT_DATALAYER_VARIABLE_RULE}${activeAccount.id}?isForDimension=true`;

               callTokenApi(urlToCall, 'GET', null)
                    .then((response) => {
                         if (response.status === 200) {
                              dispatch(
                                   setVariableOptions({
                                        listenerId: activeAccount.id,
                                        variables: response.data.datalayerVariables,
                                   })
                              );
                         }
                    })
                    .finally(() => setFetchVariableLoading(false));
          }
     };

     const fetchEvents = () => {
          if (!eventListenerId || eventListenerId !== activeAccount.id) {
               setFetchEventLoading(true);
               const urlToCall = `${API_CLIENT_EVENT_ACCOUNT_RULE}${activeAccount.id}`;

               callTokenApi(urlToCall, 'GET', null)
                    .then((response) => {
                         if (response.status === 200) {
                              dispatch(
                                   setEventOptions({
                                        listenerId: activeAccount.id,
                                        events: response.data.eventAccounts,
                                   })
                              );
                         }
                    })
                    .finally(() => setFetchEventLoading(false));
          }
     };

     const fetchOptions = () => {
          if (isFetchData) {
               fetchDatalayerVariables();
               fetchEvents();
          }
     };
     useEffect(fetchOptions, [isFetchData]); // eslint-disable-line react-hooks/exhaustive-deps

     const reloadVariableOptions = () => {
          if (automaticVariableReload) {
               setFetchVariableLoading(true);
               const urlToCall = `${API_CLIENT_DATALAYER_VARIABLE_RULE}${activeAccount.id}?isForDimension=true`;

               callTokenApi(urlToCall, 'GET', null)
                    .then((response) => {
                         if (response.status === 200) {
                              dispatch(
                                   setVariableOptions({
                                        listenerId: activeAccount.id,
                                        variables: response.data.datalayerVariables,
                                   })
                              );
                         }
                    })
                    .finally(() => setFetchVariableLoading(false));
          }
     };
     useEffect(reloadVariableOptions, [automaticVariableReload]); // eslint-disable-line react-hooks/exhaustive-deps

     return {
          fetchLoading: fetchVariableLoading || fetchEventLoading,
          reactSelectEventOptions,
          reactSelectVariableOptions,
     };
};

export const useActiveListener = () => {
     const location = useLocation();
     const activeListeners = useSelector((state) => state.subscriber.activeListeners);
     const splitPathname = location.pathname.split('/');
     const activeListener = activeListeners.find((listener) => splitPathname.some((path) => path === listener.alias));

     return activeListener;
};

export const useCustomFormListener = () => {
     const activeListeners = useSelector((state) => state.subscriber.activeListeners);
     const html = activeListeners.find((item) => item.alias === 'custom-html-forms');

     return html;
};

export const useActiveDestination = () => {
     const location = useLocation();
     const activeDestinations = useSelector((state) => state.subscriber.activeDestinations);
     const splitPathname = location.pathname.split('/');
     const activeDestination = activeDestinations.find((destination) => splitPathname.some((path) => path === destination.alias));

     return activeDestination;
};

export const useActiveReportingFeatures = () => {
     const activeFeatures = useSelector((state) => state.subscriber.activeFeatures);
     return activeFeatures.filter((feature) => feature.code.toLowerCase().includes('reporting'));
};

export const useActivePopulateFeatures = () => {
     const activeFeatures = useSelector((state) => state.subscriber.activeFeatures);
     return activeFeatures.filter((feature) => feature.code.toLowerCase().includes('populate'));
};

export const useActiveFeature = (featureType) => {
     const activeListener = useActiveListener();
     const activeFeatures = useSelector((state) => state.subscriber.activeFeatures);

     return activeFeatures.find((feature) => (feature.featureType === featureType || feature.type === featureType) && feature.listenerId === activeListener.id);
};

export const useActiveFeatureV2 = (featureType) => {
     const activeListener = useActiveListener();
     const activeFeatures = useSelector((state) => state.subscriber.activeFeatures);

     return activeFeatures.find((feature) => (feature.featureType === featureType || feature.type === featureType) && activeListener && feature.listenerId === activeListener.id);
};

export const useActiveFeatures = (featureType) => {
     const activeFeatures = useSelector((state) => state.subscriber.activeFeatures);

     return activeFeatures.find((feature) => feature.featureType === featureType || feature.type === featureType);
};
// Time zone offset of the active account
export const useTimeZoneOffset = () => {
     const timeZone = useSelector((state) => state.subscriber.activeAccount.timeZone);

     if (!timeZone) {
          return '';
     }

     return getTimeZoneOffset(timeZone);
};

export const usePrevious = (value) => {
     const ref = useRef();

     useEffect(() => {
          ref.current = value;
     });

     return ref.current;
};

export const useScript = (src) => {
     // Keep track of script status ("idle", "loading", "ready", "error")
     const [status, setStatus] = useState(src ? 'loading' : 'idle');

     useEffect(
          () => {
               // Allow falsy src value if waiting on other data needed for
               // constructing the script URL passed to this hook.
               if (!src) {
                    setStatus('idle');
                    return;
               }

               // Fetch existing script element by src
               // It may have been added by another intance of this hook
               let script = document.querySelector(`script[src="${src}"]`);

               if (!script) {
                    // Create script
                    script = document.createElement('script');
                    script.src = src;
                    script.async = true;
                    script.setAttribute('data-status', 'loading');
                    // Add script to document body
                    document.body.appendChild(script);

                    // Store status in attribute on script
                    // This can be read by other instances of this hook
                    const setAttributeFromEvent = (event) => {
                         script.setAttribute('data-status', event.type === 'load' ? 'ready' : 'error');
                    };

                    script.addEventListener('load', setAttributeFromEvent);
                    script.addEventListener('error', setAttributeFromEvent);
               } else {
                    // Grab existing script status from attribute and set to state.
                    setStatus(script.getAttribute('data-status'));
               }

               // Script event handler to update status in state
               // Note: Even if the script already exists we still need to add
               // event handlers to update the state for *this* hook instance.
               const setStateFromEvent = (event) => {
                    setStatus(event.type === 'load' ? 'ready' : 'error');
               };

               // Add event listeners
               script.addEventListener('load', setStateFromEvent);
               script.addEventListener('error', setStateFromEvent);

               // Remove event listeners on cleanup
               return () => {
                    if (script) {
                         script.removeEventListener('load', setStateFromEvent);
                         script.removeEventListener('error', setStateFromEvent);
                    }
               };
          },
          [src] // Only re-run effect if script src changes
     );

     return status;
};

export const useStickyHeader = (defaultSticky = false) => {
     const [isSticky, setIsSticky] = useState(defaultSticky);
     const tableRef = useRef(null);

     const handleScroll = useCallback(
          ({ top, bottom }) => {
               if (top <= 0 && bottom > 2 * 68) {
                    !isSticky && setIsSticky(true);
               } else {
                    isSticky && setIsSticky(false);
               }
          },
          [isSticky]
     );

     useEffect(() => {
          const handleScroll = () => {
               handleScroll(tableRef.current.getBoundingClientRect());
          };
          window.addEventListener('scroll', handleScroll);

          return () => {
               window.removeEventListener('scroll', handleScroll);
          };
     }, [handleScroll]);

     return { tableRef, isSticky };
};

// isUnmounted?
export const useIsUnmounted = () => {
     const isUnmounted = useRef(false);

     useEffect(() => {
          return () => {
               isUnmounted.current = true;
          };
     }, []);

     return isUnmounted; // returning "isUnmounted.current" wouldn't work because we would return immutable primitive
};

// Do not allow custom supports to DuseDispatch() this component
export const useDoNotAllowSupport = () => {
     const history = useHistory();
     const isCustomerSupportLoginAs = useSelector((state) => state.subscriber.isCustomerSupportLoginAs);

     useEffect(() => {
          if (isCustomerSupportLoginAs) {
               history.push('/');
          }
     }, [isCustomerSupportLoginAs, history]);
};

// Convert title to slug
export const convertToSlug = (Text) => {
     return Text.trim()
          .toLowerCase()
          .replace(/ /g, '-')
          .replace(/[^\w-]+/g, '');
};
export const convertToEcommerce = (Text) => {
     return Text.trim()
          .toLowerCase()
          .replace(/ /g, '_')
          .replace(/-/g, '')
          .replace(/[^\w-\s]+/g, '')
          .replace(/_+/g, '_');
};

// Fetch Browser Storage Variables
export const useBrowserVariables = (needToFetch = false) => {
     const dispatch = useDispatch();
     const activeAccountId = useSelector((state) => state.subscriber.activeAccount.id);
     const customBrowserVariables = useSelector((state) => state.subscriber.customBrowserVariables);
     const browserVariableOptions = useSelector((state) => state.subscriber.browserVariableOptions);
     const [isLoading, setIsLoading] = useState(false);
     let browserVariableShortCodes = [];

     if (browserVariableOptions.length > 0) {
          browserVariableShortCodes = browserVariableOptions.filter((item) => !!item.type).map((el) => `browser_storage:${el.type.split(' ')[0].toLowerCase()}:${el.name}`);
     }

     const fetchBrowserVariables = () => {
          if (needToFetch && Array.isArray(browserVariableOptions) && browserVariableOptions.length === 0) {
               setIsLoading(true);
               callTokenApi(`${API_CLIENT_CUSTOM_VARIABLE}${activeAccountId}?group=browser`, 'GET', null)
                    .then((response) => {
                         if (response.status === 200) {
                              const { count, variables } = response.data;
                              let initialVariables = [],
                                   listDisabled = [];

                              if (count >= 1) {
                                   variables.forEach((item) => {
                                        initialVariables.push({ id: item.id, name: item.name, type: item.type });
                                        listDisabled.push(true);
                                   });

                                   dispatch(
                                        setBrowserVariables({
                                             initialVariables,
                                             addedVariables: [],
                                             editedVariables: [],
                                             listDisabled: [...listDisabled],
                                             numOfVariables: count,
                                        })
                                   );
                                   dispatch(setBrowserVariableOptions(variables));
                              } else {
                                   dispatch(
                                        setBrowserVariables({
                                             initialVariables: [{ id: 'variable1', name: '', type: '' }],
                                             addedVariables: [{ id: 'variable1', name: '', type: '' }],
                                             editedVariables: [],
                                             listDisabled: [false],
                                             numOfVariables: 1,
                                        })
                                   );
                              }
                         } else {
                              toastError(response);
                         }
                    })
                    .finally(() => {
                         setIsLoading(false);
                    });
          }
     };

     useEffect(fetchBrowserVariables, [needToFetch]); // eslint-disable-line react-hooks/exhaustive-deps

     return { customBrowserVariables, browserVariableOptions, browserVariableShortCodes, fetchBrowserVariablesLoading: isLoading };
};

export const useFetchMasterclassInfo = (needToFetch = true) => {
     const dispatch = useDispatch();
     const masterclassCourse = useSelector((state) => state.subscriber.masterclassCourse);
     const [isLoading, setIsLoading] = useState(true);

     const fetchData = () => {
          if (needToFetch && (!masterclassCourse || !masterclassCourse.id)) {
               callTokenApi(API_CLIENT_GET_COURSE, 'GET')
                    .then((response) => {
                         if (response.status === 200) {
                              const { course, coupons } = response.data;

                              dispatch(setMasterclassCourse({ ...course, coupons: coupons || [] }));
                         } else {
                              toastError(response);
                         }
                    })
                    .finally(() => setIsLoading(false));
          } else {
               setIsLoading(false);
          }
     };

     useEffect(fetchData, [needToFetch]); // eslint-disable-line react-hooks/exhaustive-deps

     return { masterclassCourseLoading: isLoading, masterclassCourse };
};

export const useAllMCLessons = () => {
     const sections = useSelector((state) => state.subscriber.masterclassCourse.sections);
     const [allLessons, setAllLessons] = useState([]);

     useEffect(() => {
          let lessons = [];

          if (sections) {
               sections.forEach((section) => {
                    lessons = [...lessons, ...section.lessons];
               });
          }

          setAllLessons(lessons);
     }, [sections]);

     return allLessons;
};

export const useSubscriberGTMTracking = (isNoAccount) => {
     const [tracked, setTracked] = useState(false);
     const user = useSelector((state) => state.theme.user);
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);

     useEffect(() => {
          if (!tracked && (isNoAccount || activeAccount.id)) {
               const userDetails = {
                    email: user.email,
                    userid: user.id,
               };

               let accountDetails;

               if (activeAccount.id) {
                    accountDetails = {
                         accountid: activeAccount.id,
                         accountname: activeAccount.name,
                    };
               }

               gtmTrackingIdentifyLLUser(userDetails, accountDetails);
               setTracked(true);
          }
     }, [user, activeAccount, tracked, isNoAccount]);
};

export const useUserGTMTracking = () => {
     const handleFirstLoad = () => {
          const token = cookie.get('token');

          if (token) {
               const user = tryParseJSON(localStorage['user']);
               let userDetails;

               if (user) {
                    userDetails = {
                         email: user.email,
                         userid: user.id,
                    };
               }

               gtmTrackingIdentifyLLUser(userDetails);
          }
     };

     useEffect(handleFirstLoad, []);
};

export const useScrollFaded = ({ hasScroll = false }) => {
     const [isFadedTop, setIsFadedTop] = useState(false);
     const [isFadedBottom, setIsFadedBottom] = useState(hasScroll);
     const debounceScroll = useRef(
          debounce((element) => {
               const isScrollTop = element.scrollTop === 0;
               const isScrollEnd = element.offsetHeight + element.scrollTop >= element.scrollHeight;

               setIsFadedTop(!isScrollTop);
               setIsFadedBottom(!isScrollEnd);
          }, 20)
     );

     // useEffect(() => {
     //      setIsFadedBottom(hasScroll);
     // }, [hasScroll]);

     const onScrollContainerList = (e) => {
          // Prevents React from resetting its properties:
          e.persist();
          debounceScroll.current(e.target);
     };

     const containerListClass = classNames({ scrollbar: hasScroll }, { 'faded-top': isFadedTop }, { 'faded-bottom': isFadedBottom });

     return { containerListClass, onScrollContainerList };
};

// Setup account completed percentage
export const useCompletedPercentage = ({ completedSteps }) => {
     const getPercentage = useCallback((completedSteps) => {
          let percentage = 0;

          if (Array.isArray(completedSteps)) {
               let stepsToCalculatePercentage = { ...CREATE_ACCOUNT_MAIN_STEPS };

               // Don't count user role, GTM experience and job info to percentage
               delete stepsToCalculatePercentage.userWork;
               delete stepsToCalculatePercentage.userSizeCompany;
               delete stepsToCalculatePercentage.userDesRole;
               delete stepsToCalculatePercentage.userRevenue;
               delete stepsToCalculatePercentage.userIdentify;
               delete stepsToCalculatePercentage.userCompliant;
               delete stepsToCalculatePercentage.userAutomates;
               delete stepsToCalculatePercentage.userCentralize;
               delete stepsToCalculatePercentage.userAnalytics;
               delete stepsToCalculatePercentage.userConsent;
               delete stepsToCalculatePercentage.userSessionReplay;
               delete stepsToCalculatePercentage.accountPackage;
               delete stepsToCalculatePercentage.userReplace;

               const checkIfValid = (step) => {
                    return Object.entries(stepsToCalculatePercentage).findIndex(([_, value]) => value === step) > -1;
               };

               let validCompletedSteps = 0;

               completedSteps.forEach((completedStep) => {
                    if (checkIfValid(completedStep)) {
                         validCompletedSteps++;
                    }
               });

               const allSteps = Object.entries(stepsToCalculatePercentage).length;
               percentage = Math.round((validCompletedSteps / allSteps) * 100);
          }

          return percentage;
     }, []);

     const percentage = useMemo(() => getPercentage(completedSteps), [completedSteps, getPercentage]);

     return percentage;
};

export const useMainInvoiceItems = (account) => {
     let activeAccount = useSelector((state) => state.subscriber.activeAccount);

     if (account) {
          activeAccount = account;
     }

     let useMainInvoiceItems = {};

     switch (activeAccount.packageVersion) {
          case 1:
               useMainInvoiceItems.accountNumberOfInvoiceItemsPerMonth = activeAccount.numberOfEventsPerMonth;
               useMainInvoiceItems.packageNumberOfInvoiceItemsPerMonth = activeAccount.packageNumberOfEventsPerMonth;
               useMainInvoiceItems.singularInvoiceType = 'Event';
               useMainInvoiceItems.pluralInvoiceType = 'Events';
               break;
          case 2:
               useMainInvoiceItems.accountNumberOfInvoiceItemsPerMonth = activeAccount.numberOfVisitorsPerMonth;
               useMainInvoiceItems.packageNumberOfInvoiceItemsPerMonth = activeAccount.packageNumberOfVisitors;
               useMainInvoiceItems.singularInvoiceType = 'User';
               useMainInvoiceItems.pluralInvoiceType = 'Users';
               break;
          case 3:
               useMainInvoiceItems.accountNumberOfInvoiceItemsPerMonth = activeAccount.numberOfVisitorsPerMonth;
               useMainInvoiceItems.packageNumberOfInvoiceItemsPerMonth = activeAccount.packageNumberOfVisitors;
               useMainInvoiceItems.singularInvoiceType = 'User';
               useMainInvoiceItems.pluralInvoiceType = 'Users';
               break;
          default:
     }

     return useMainInvoiceItems;
};

export const useIsLatestPlan = () => {
     return useSelector((state) => state.subscriber.activeAccount.packageVersion === LATEST_PACKAGE_VERSION);
};

export const useTestPlan = () => {
     return useSelector((state) => state.subscriber.packages.find((pk) => pk.pricePerMonth === 0));
};

export const useIsPreviewDomainIframeBlocked = () => {
     const { domains, domain } = useSelector((state) => state.subscriber.preview);
     const foundDomain = domains.find((d) => d.value === domain);

     return foundDomain && foundDomain.isIframeBlocked;
};

export const useIsPreviewDomainCSPBlocked = () => {
     const { domains, domain } = useSelector((state) => state.subscriber.preview);
     const foundDomain = domains.find((d) => d.value === domain);

     return foundDomain && foundDomain.isCSPBlocked;
};

export const useCancelToken = () => {
     const axiosSource = useRef(null);
     const getCancelToken = useCallback(() => {
          axiosSource.current = CancelToken.source();
          return axiosSource.current.token;
     }, []);

     useEffect(
          () => () => {
               if (axiosSource.current) axiosSource.current.cancel();
          },
          []
     );

     return getCancelToken;
};

export const useHasPublishGTMPermission = () => {
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     let hasPermission = false;

     if (activeAccount.gtmInfo && activeAccount.gtmInfo.currentContainer) {
          const { currentContainer, email: gtmUserEmail } = activeAccount.gtmInfo;

          if (Array.isArray(currentContainer.userPermissions)) {
               const foundUserPermission = currentContainer.userPermissions.find((permission) => permission.emailAddress === gtmUserEmail);

               if (foundUserPermission) {
                    const foundContainerPermission = foundUserPermission.containerAccess.find((containerAccess) => containerAccess.containerId === currentContainer.containerId);

                    if (foundContainerPermission) {
                         hasPermission = foundContainerPermission.permission === 'publish';
                    }
               }
          }
     }

     return hasPermission;
};

export const useSimplePagination = ({ items, itemsPerPage = 0 }) => {
     const [activePage, setActivePage] = useState(1);
     const [totalPages, setTotalPages] = useState(1);

     useEffect(() => {
          setTotalPages(Array.isArray(items) && items.length > 0 ? Math.ceil(items.length / itemsPerPage) : 1);
     }, [items]); // eslint-disable-line react-hooks/exhaustive-deps

     const itemsToDisplay = useMemo(() => {
          const fromIndex = itemsPerPage * (activePage - 1);
          const toIndex = fromIndex + itemsPerPage;

          return items ? items.slice(fromIndex, toIndex) : [];
     }, [activePage, items]); // eslint-disable-line react-hooks/exhaustive-deps

     return { itemsToDisplay, activePage, setActivePage, totalPages, setTotalPages };
};

export const useCurrentGTMContainer = () => {
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);

     return activeAccount.gtmInfo && activeAccount.gtmInfo.currentContainer;
};

export const useDidMount = () => {
     const isDidMount = useRef(false);

     useEffect(() => {
          isDidMount.current = true;
     }, []);

     return isDidMount.current;
};

export const useResizeObserver = (ref, callback) => {
     useEffect(() => {
          let element;
          if (ref) {
               element = ref.current;
          }

          if (!element) return;

          const resizeObserver = new ResizeObserver((entries) => {
               for (let entry of entries) {
                    if (entry.target === element) {
                         callback();
                         break;
                    }
               }
          });

          resizeObserver.observe(element);

          return () => {
               resizeObserver.unobserve(element);
          };
     }, [ref, callback]);
};

export const useDebouncedCallback = (callback, delay) => {
     const timer = useRef(null);
 
     const debouncedFunction = useCallback(
         (...args) => {
             if (timer.current) {
                 clearTimeout(timer.current);
             }
             timer.current = setTimeout(() => {
                 callback(...args);
             }, delay);
         },
         [callback] //eslint-disable-line
     );
 
     const cancel = useCallback(() => {
         if (timer.current) {
             clearTimeout(timer.current);
             timer.current = null;
         }
     }, []);
 
     return [debouncedFunction, cancel];
 };